import { makeVar, useReactiveVar } from '@apollo/client';

type WsClientData = {
  isConnectionActive: boolean;
};

export const wsStatusVar = makeVar<WsClientData | undefined>(undefined);

export function setWsConnectionStatus(isActive: boolean) {
  wsStatusVar({
    isConnectionActive: isActive,
  });
}

export const useWsClientStatus = () => {
  const data = useReactiveVar(wsStatusVar);
  return {
    isConnectionActive: !!data?.isConnectionActive,
  };
};
