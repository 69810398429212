import { ApolloError } from '@apollo/client';

import {
  STRIPE_ERROR_CODE_CARD_DECLINED,
  STRIPE_ERROR_TYPE_CARD_ERROR,
} from 'global/constants';

const getServerErrorObject = (error?: ApolloError) => {
  const fullMessage = (error?.networkError as any)?.result?.errors?.[0]
    ?.message;

  let type, code, message;
  if (fullMessage?.includes('|')) {
    [type, code, message] = fullMessage.split('|');
  } else {
    message = fullMessage;
  }

  return { type, code, message };
};

export const getServerErrorMessage = (
  error?: ApolloError
): string | null | undefined => getServerErrorObject(error).message;

export const getServerErrorReason = (
  error?: ApolloError
): string | null | undefined =>
  (error?.networkError as any)?.result?.reason?.[0]?.message;

export const isCardDeclinedError = (error?: ApolloError) => {
  const { type, code } = getServerErrorObject(error);
  return (
    type === STRIPE_ERROR_TYPE_CARD_ERROR ||
    code === STRIPE_ERROR_CODE_CARD_DECLINED
  );
};
