import styled, { css } from 'styled-components';

import { rem } from 'approot/shared/style';
import { SecondaryBaseStyled } from './secondary-button.style';
import { spacing12, spacing16 } from '../spacing.style';
import { grey1, grey5, grey10 } from '../colours.style';
import { baseFlexCss, flexSpaceBetweenCss } from '../flex.style';

const baseItemCss = css`
  ${flexSpaceBetweenCss}
  border-radius: ${rem(12)};
  height: auto;
  min-height: ${rem(70)};
  margin-bottom: ${rem(spacing16)};
  padding: ${rem(spacing12)};
  width: 100%;
  text-align: left;
`;

export const ListButton = styled.button`
  ${SecondaryBaseStyled}
  ${baseItemCss}

  & > svg {
    flex-shrink: 0;
    fill: ${grey10};
    stroke: none;
    transform: rotate(-90deg);
  }
`;

export const ListItem = styled.div`
  ${baseItemCss}
  background-color: ${grey1};
  border: 1px solid ${grey5};
`;

export const ListButtonImageWrapper = styled.div`
  margin-right: ${rem(spacing12)};
`;

export const ListItemDetails = styled.div`
  ${baseFlexCss}
  width: 100%;
`;
