import { gql } from '@apollo/client';

import { SubjectFragment } from 'approot/shared/graphql/startup-data/startup-data.graphql';
import { unitSubStrandsFragment } from 'approot/unit/sections/strands/strands.graphql';

export const unitCategoryDetailsFragment = gql`
  fragment UnitCategoryDetailsFragment on Unit {
    topic {
      id
      label
      slug
      topicStatus
      units {
        id
        sort
        slug
        name
        unitStatus
      }
    }
    subjects {
      ...SubjectFragment
    }
    years {
      id
      name
      label
    }
  }
  ${SubjectFragment}
`;

export const GET_UNIT_TOPIC_OPTIONS = gql`
  query GetUnitTopicOptions($input: QueryTopicsInput!) {
    topics(input: $input) {
      id
      label
      topicStatus
      years {
        name
      }
    }
  }
`;

export const SAVE_UNIT_TOPIC = gql`
  mutation SaveUnitTopic($input: UpdateUnitTopicInput!) {
    updateUnitTopic(input: $input) {
      ...UnitSubStrandsFragment
      ...UnitCategoryDetailsFragment
    }
  }
  ${unitSubStrandsFragment}
  ${unitCategoryDetailsFragment}
`;
