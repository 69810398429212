import format from 'date-fns/format';
import add from 'date-fns/add';
import { TFunction } from 'i18next';

import { DateFormatParams } from 'approot/shared/dates/dates.constants';

export const ISO_OFFSET_FORMAT = 'xxx';

export function formatDate(
  date: string | Date,
  formatString: string = 'd MMMM yyyy'
) {
  return format(typeof date === 'string' ? new Date(date) : date, formatString);
}

export function formatDateWithDay(
  date: string | Date,
  formatString: string = 'EEEE d MMM'
): string {
  return format(typeof date === 'string' ? new Date(date) : date, formatString);
}

export function addDays(date: Date, numberOfDays: number) {
  return add(date, {
    days: numberOfDays,
  });
}

export function defaultDateFormat(
  date: Date | string,
  t: TFunction,
  formatParams?: DateFormatParams
): string {
  return t('dateFormat', {
    val: typeof date === 'string' ? new Date(date) : date,
    formatParams: {
      val: formatParams
        ? formatParams
        : { year: 'numeric', month: 'long', day: 'numeric' },
    },
  });
}

export function shortMonthFormat(date: Date | string, t: TFunction) {
  return defaultDateFormat(date, t, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export function formatTime(date: Date) {
  return format(date, 'h:mma').toLowerCase();
}
