import i18n, { BackendModule } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { isDevEnv } from 'approot/shared/layout/header/header.utils';
import { REGION_AU, REGION_US } from 'global/constants';

export const NAMESPACE_TRANSLATION = 'translation';
export const NAMESPACE_ACCOUNT_TRANSLATION = 'account-translation';
export const NAMESPACE_PRICING_TRANSLATION = 'pricing-translation';
export const NAMESPACE_ROUTES_TRANSLATION = 'routes-translation';
export const NAMESPACE_SUPPORT = 'support-translation';

const LazyImport: BackendModule = {
  type: 'backend',
  init: function() {},
  read: function(language, namespace, callback) {
    import(`global/lang/${language}/${namespace}.json`).then(obj => {
      callback(null, obj);
    });
  },
};

function getLangRegion(region: string) {
  //map the regions from config to the format expected by i18n
  switch (region) {
    case REGION_AU:
      return 'en-au';
    case REGION_US:
      return 'en-us';
  }
  return 'en-au';
}

async function init() {
  const region = getLangRegion(process.env.REACT_APP_REGION || REGION_AU);

  i18n
    .use(LazyImport)
    .use(initReactI18next)
    .init({
      lng: region,
      fallbackLng: region,
      supportedLngs: ['en-au', 'en-us'],
      lowerCaseLng: true,
      load: 'currentOnly',
      debug: isDevEnv() && process.env.NODE_ENV !== 'test',
      interpolation: {
        escapeValue: false,
      },
    });
}

init();

export default i18n;
