import { makeVar, useReactiveVar } from '@apollo/client';

import { REGION_AU, REGION_US } from 'global/constants';
import { Regions } from './region.constants';
import { guessCurrentRegion } from './region.utils';

type RegionBanner = {
  isVisible: boolean;
  height: number;
  region: Regions;
  called: boolean;
};

type Data = {
  region: Regions;
  regionBanner: RegionBanner;
};

const currentRegion = guessCurrentRegion();

export const regionDataVar = makeVar<Data>({
  region: (process.env.REACT_APP_REGION as Regions) || REGION_AU,
  regionBanner: {
    isVisible: currentRegion !== process.env.REACT_APP_REGION,
    region: currentRegion,
    height: 0,
    called: false,
  },
});

export function setRegionBannerHeight(el: HTMLDivElement | null) {
  const data = regionDataVar();
  regionDataVar({
    ...data,
    regionBanner: {
      ...data.regionBanner,
      height: el?.offsetHeight || 0,
      called: true,
    },
  });
}

export function setRegionBannerClose() {
  const data = regionDataVar();
  regionDataVar({
    ...data,
    regionBanner: {
      ...data.regionBanner,
      height: 0,
      isVisible: false,
    },
  });
}

export const useRegionData = () => {
  const data = useReactiveVar(regionDataVar);
  return {
    ...data,
    isRegionAU: data.region === REGION_AU,
    isRegionUS: data.region === REGION_US,
  };
};
