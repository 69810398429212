import { formatDate, ISO_OFFSET_FORMAT } from 'approot/shared/logic/dates';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import {
  DataTrackerPageParams,
  DataEventAttrs,
  DATA_TRACKER_BLACKLIST,
  DATA_TRACKER_EVENT_CATEGORY,
  DATA_TRACKER_EVENT_ACTION,
  DATA_TRACKER_EVENT_TARGET_TYPE,
  DATA_TRACKER_EVENT_TARGET_IDENTIFIER,
  DATA_TRACKER_TIMESTAMP,
  DATA_TRACKER_TIMEZONE_OFFSET,
  DATA_TRACKER_ENCODE_SINGLE_QUOTE,
  DATA_TRACKER_ENCODE_SPECIAL_CHARS,
} from './data-tracker.constants';

const DATA_EVENT_DELIMETER = '|';

export function formatTimestampValues() {
  const date = new Date();
  return {
    [DATA_TRACKER_TIMESTAMP]: date.toISOString(),
    [DATA_TRACKER_TIMEZONE_OFFSET]: formatDate(date, ISO_OFFSET_FORMAT),
  };
}

/**
 * Special Character Handling in Clickstream Data
 *
 * The following functions have been provided by the data team
 * to handle special characters within the clickstream data.
 * Detailed information can be found in the Confluence documentation linked below.
 *
 * Confluence Link:
 * Clickstream Data Tracker Special Character Handling
 * https://inquisitive.atlassian.net/wiki/x/BgC_ew
 */

function encodeRFC3986URIComponent(value: string) {
  return encodeURIComponent(value).replace(
    /[!'()*]/g,
    c =>
      `%${c
        .charCodeAt(0)
        .toString(16)
        .toUpperCase()}`
  );
}

function encodeURIWithSingleQuote(value: string) {
  return encodeURI(value).replace(
    /'/g,
    c => `%${c.charCodeAt(0).toString(16)}`
  );
}

type DataTrackerEncodeOptions =
  | typeof DATA_TRACKER_ENCODE_SINGLE_QUOTE
  | typeof DATA_TRACKER_ENCODE_SPECIAL_CHARS;

export function dataTrackerEncodeValue({
  value,
  encoderFunction,
}: {
  value: string;
  encoderFunction: DataTrackerEncodeOptions;
}) {
  if (encoderFunction === DATA_TRACKER_ENCODE_SINGLE_QUOTE) {
    return encodeURIWithSingleQuote(value);
  }
  if (encoderFunction === DATA_TRACKER_ENCODE_SPECIAL_CHARS) {
    return encodeRFC3986URIComponent(value);
  }
  return value;
}

export function sanitizeQueryParams(queryParams: {
  [key: string]: string | string[];
}) {
  const pageParams: { [key: string]: any } = {};
  for (let key in queryParams) {
    if (!DATA_TRACKER_BLACKLIST.includes(key)) {
      pageParams[key] =
        typeof queryParams[key] === 'string'
          ? dataTrackerEncodeValue({
              value: queryParams[key] as string,
              encoderFunction: DATA_TRACKER_ENCODE_SPECIAL_CHARS,
            })
          : queryParams[key];
    }
  }
  return pageParams;
}

export function convertPageParamsToArrays(params: {
  [key: string]: string | string[];
}) {
  const pageParams: DataTrackerPageParams = {};
  for (let key in params) {
    if (Array.isArray(params[key])) {
      pageParams[key] = params[key] as string[];
    } else {
      pageParams[key] = [params[key] as string];
    }
  }
  return pageParams;
}

export function mergePageParamValues(
  queryParams: DataTrackerPageParams,
  manualPageParams: DataTrackerPageParams
) {
  if (manualPageParams) {
    return {
      ...queryParams,
      ...manualPageParams,
    };
  }
  return queryParams;
}

export function downcaseAndDedupePageParams(
  params: DataTrackerPageParams
): DataTrackerPageParams {
  // sort so that we prioritise any lowercase keys over duplicated uppercase ones
  const sortedEntries = orderBy(Object.entries(params), ([key]) => key, 'desc');
  const deduplicatedEntries = uniqBy(sortedEntries, ([key]) =>
    key.toLowerCase()
  );
  const downcasedEntries = deduplicatedEntries.map(([key, value]) => [
    key.toLowerCase(),
    value,
  ]);
  return Object.fromEntries(downcasedEntries);
}

export function createGoogleAnalyticsDataEvent(dataEvent: DataEventAttrs) {
  if (!dataEvent || !dataEvent['event_data']) {
    return {};
  }

  const eventData = dataEvent['event_data'];
  let gtmValue = `${eventData[DATA_TRACKER_EVENT_TARGET_TYPE]}${
    eventData[DATA_TRACKER_EVENT_TARGET_IDENTIFIER]
      ? `${DATA_EVENT_DELIMETER}${eventData[DATA_TRACKER_EVENT_TARGET_IDENTIFIER]}`
      : ''
  }`;

  for (let key in eventData) {
    if (
      key !== DATA_TRACKER_EVENT_TARGET_TYPE &&
      key !== DATA_TRACKER_EVENT_TARGET_IDENTIFIER
    ) {
      const eventDataValues = eventData[key];
      gtmValue += `${DATA_EVENT_DELIMETER}${key}${DATA_EVENT_DELIMETER}${
        Array.isArray(eventDataValues)
          ? eventDataValues.join(',')
          : eventDataValues
      }`;
    }
  }

  return {
    event: dataEvent[DATA_TRACKER_EVENT_CATEGORY],
    'event-category': dataEvent[DATA_TRACKER_EVENT_CATEGORY],
    'event-action': dataEvent[DATA_TRACKER_EVENT_ACTION],
    'event-label': gtmValue,
  };
}
