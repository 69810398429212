import { css } from 'styled-components';

export const fadeInContent = css`
  @keyframes fade-in-content {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  opacity: 0;
  animation: fade-in-content 0.3s ease-in forwards;
`;

export const skeletonState = css`
  @keyframes skeletonPulse {
    50% {
      opacity: 0.5;
    }
  }
  animation: skeletonPulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;
