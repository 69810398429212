import {
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { getUrlSearchParams } from 'approot/shared/logic/strip-location-params';
import { pushDataTrackerPageView } from 'approot/shared/data-tracker/data-tracker-push-pageview';
import { useKeyBoardTrap } from 'approot/shared/ally/keyboard.hooks';
import { LAYOUT_TRANSITION_DURATION } from './layout.styles';
import {
  FAQS_ID,
  PUBLIC_HOME,
  PUBLIC_SCOPE_AND_SEQUENCE,
} from 'global/public-routes.constants';
import { setContentFilterData } from 'approot/shared/filters/filters.apollo';
import { isUserClassHomeUrl } from 'approot/shared/logic/is-user-class-home-url';
import { signOut } from 'approot/shared/signin/signin.utils';

export const MEDIA_IS_TOUCH = `(hover: none) and (pointer: coarse)`;

export const useDrawerLayout = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
  isActive: boolean,
  captureKeypress: boolean = true,
  close: () => void
) => {
  const pageOffset = useRef<number>(0);

  useKeyBoardTrap({
    containerRef: containerRef.current,
    isActive,
    captureKeypress,
    close,
  });

  useLayoutEffect(() => {
    const isTouch = window.matchMedia(MEDIA_IS_TOUCH);
    if (isTouch.matches) {
      if (isActive) {
        pageOffset.current = window.pageYOffset;
        document.body.style.top = `-${pageOffset.current}px`;
        document.body.style.width = '100%';
        document.body.style.position = 'fixed';
      } else {
        const scrollY = pageOffset.current;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, scrollY);
        document.body.style.overflow = '';
      }
    } else {
      if (isActive) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }

    return () => {
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
      document.body.style.overflow = '';
    };
  }, [isActive]);
};

export const useDrawerQueryStringUpdate = (
  isActive: boolean,
  queryParams?: { [key: string]: string }
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (queryParams) {
      if (isActive) {
        // update browser URL with params if they exist
        setSearchParams({
          ...getUrlSearchParams(searchParams),
          ...queryParams,
        });
        pushDataTrackerPageView();
      } else {
        // clean up browser URL params
        const qp = { ...getUrlSearchParams(searchParams) };
        const keysLength = Object.keys(qp).length;
        for (let key in queryParams) {
          delete qp[key];
        }

        if (keysLength !== Object.keys(qp).length) {
          // only update if the URL changed
          setSearchParams({ ...qp });
          pushDataTrackerPageView();
        }
      }
    }
    //eslint-disable-next-line
  }, [isActive, queryParams]);
};

export const useDrawerScrollTop = (
  navRef: MutableRefObject<HTMLDivElement | null>,
  scrollRef: MutableRefObject<HTMLDivElement | null>,
  isActive: boolean
) => {
  useLayoutEffect(() => {
    if (isActive) {
      if (scrollRef?.current) {
        scrollRef.current.scrollTop = 0;
        setTimeout(() => {
          if (navRef?.current) {
            navRef.current.focus();
          }
        }, LAYOUT_TRANSITION_DURATION);
      }
    }
  }, [isActive, scrollRef, navRef]);
};

export const usePageViewLayout = (triggerOnLoad = true) => {
  // this will push 1 page view event when the layout mounts
  useEffect(() => {
    if (triggerOnLoad) pushDataTrackerPageView();
    // eslint-disable-next-line
  }, []);
};

export const useLayoutLinks = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const isOnHomePage = pathname === PUBLIC_HOME || isUserClassHomeUrl(pathname);

  const goToHome = useCallback(
    (e: MouseEvent) => {
      if (!isOnHomePage) {
        setContentFilterData({
          readPreferenceFilter: true,
        });
      } else {
        e.preventDefault();
      }
    },
    [isOnHomePage]
  );

  const goToScopeAndSequence = useCallback(
    (e: MouseEvent) => {
      if (!pathname.includes(PUBLIC_SCOPE_AND_SEQUENCE)) {
        setContentFilterData({
          readPreferenceFilter: true,
        });
      } else {
        e.preventDefault();
      }
    },
    [pathname]
  );

  const goToFaqs = useCallback(() => {
    document.getElementById(FAQS_ID)?.scrollIntoView();
  }, []);

  return {
    goToHome,
    goToScopeAndSequence,
    goToFaqs,
    signOut: signOut,
  };
};

export function removeSplashLoading() {
  const el = document.getElementById('inline-splash-loading');
  if (el && el.parentNode) {
    el.style.zIndex = '1';
  }
  const logo = document.getElementById('splash-icon-wrapper');
  if (logo) {
    logo.style.display = 'none';
  }
}

export const useLayoutSplash = () => {
  useLayoutEffect(() => {
    // this element sits in index.html for fast loading
    removeSplashLoading();
  }, []);
};
