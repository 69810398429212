import { gql } from '@apollo/client';

export const GET_PRICING_INFO = gql`
  query PricingInfo {
    pricing {
      id
      standardPrice
      wholeSchoolPrice
      wholeSchoolDiscount
      standardPricePlanId
      wholeSchoolPricePlanId
      individualTrialDuration
      schoolTrialDuration
    }
  }
`;

export const dynamicPricingFragment = gql`
  fragment DynamicPricingFragment on PricingInfo {
    id
    total
    standardPrice
    wholeSchoolPrice
    standardPricePlanId
    wholeSchoolPricePlanId
    taxCategory
    totalTax
    isValidPromoCode
    percentOff
    discountApplied
    fee
    creditUsed
    creditRemaining
  }
`;

export const GET_DYNAMIC_PRICING_JOIN = gql`
  query DynamicPricingJoin($input: PricingInput!) {
    dynamicPricingJoin(input: $input) {
      amountOff
      ...DynamicPricingFragment
    }
  }
  ${dynamicPricingFragment}
`;

export const GET_DYNAMIC_PRICING_ACCOUNTS = gql`
  query DynamicPricingAccounts($input: PricingInput!) {
    dynamicPricingAccount(input: $input) {
      ...DynamicPricingFragment
    }
  }
  ${dynamicPricingFragment}
`;

export const GET_DYNAMIC_PRICING_BUY_MEMBERSHIP = gql`
  query DynamicPricingBuyMembership($input: PricingInput!) {
    dynamicPricingBuyMembership(input: $input) {
      ...DynamicPricingFragment
    }
  }
  ${dynamicPricingFragment}
`;
