import { Component, ReactNode } from 'react';

import { logException } from './approot/shared/debug';
import QuisiStatic from 'approot/shared/quisi/quisi-static';
import { spacing48, spacing80 } from 'approot/shared/styles/spacing.style';
import { PrimaryButton } from 'approot/shared/styles/buttons';
import { PStyled } from 'approot/shared/styles/typography.style';

type AnyObject = {
  [key: string]: any;
};

type Props = { children: ReactNode };

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch = (error: Error, info: AnyObject) => {
    console.error('componentDidCatch', error, info);
    let infoString: string;
    try {
      infoString = JSON.stringify(info);
    } catch (e) {
      infoString = info && info.toString();
    }
    logException({
      sourceFile: 'error-boundary.tsx',
      message: infoString,
      e: error,
    });
    this.setState({ hasError: true });
  };

  reloadApp = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center' }}>
          <QuisiStatic marginTop={spacing80} />
          <PStyled marginBottom={spacing48}>Something went wrong :(</PStyled>
          <PrimaryButton type="button" onClick={this.reloadApp}>
            Try again
          </PrimaryButton>
        </div>
      );
    }
    return this.props.children;
  }
}
