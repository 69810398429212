import { gql } from '@apollo/client';

export const schoolFragment = gql`
  fragment SchoolDetailsFragment on School {
    id
    name
    postcode
    suburb
    campus
    state
    schoolType
    typeOfSchool
    schoolSector
    country
    hasAdmins
  }
`;
