import styled from 'styled-components';

import { rem } from 'approot/shared/style';
import { grey1, grey8 } from 'approot/shared/styles/colours.style';

type SpinnerProps = {
  light?: boolean;
};

export const Spinner = styled.svg<SpinnerProps>`
  @keyframes spinIt {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  display: inline-block;
  width: ${rem(13)};
  height: ${rem(13)};
  animation: spinIt 2s infinite linear;

  path {
    fill: ${props => (props.light ? grey1 : grey8)};
  }
`;
