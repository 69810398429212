import { JPG_QUALITIES } from 'global/constants';
import { ResponsiveImage } from './imgix-responsive.constants';

export const ORIGINAL_IMAGE = 'ORIGINAL_IMAGE';

const REGULAR_QUALITY = Math.max(...JPG_QUALITIES);
const LOW_QUALITY = Math.min(...JPG_QUALITIES);

export const responsiveImageUrl = (
  responsiveImages: ResponsiveImage[],
  cssPixelWidth: number | string,
  cssPixelHeight: undefined | null | number,
  devicePixelRatio: undefined | null | number
): undefined | string => {
  const images: ResponsiveImage[] = [...responsiveImages] // make a copy so we don't sort the source
    .sort((a, b) => b.width - a.width);

  if (typeof cssPixelWidth !== 'number') {
    throw Error('Invalid width: "' + cssPixelWidth + '"');
  }

  let pixelRatio = 1;
  if (devicePixelRatio) {
    pixelRatio = devicePixelRatio;
  } else if (window.devicePixelRatio) {
    pixelRatio = window.devicePixelRatio;
  }

  if (!cssPixelWidth || !pixelRatio) {
    throw Error(
      `Unable to calculate imgix-responsive width with ${cssPixelWidth} and ${pixelRatio}`
    );
  }

  const width: number = cssPixelWidth * pixelRatio;

  let validImages = images.filter(image => image.width >= width);
  if (validImages.length === 0) {
    validImages = images;
  } else {
    validImages.reverse();
  }
  if (validImages.length === 0) return;

  const responsiveImageUrl = validImages[0].urls.find(
    url => url.quality === (pixelRatio > 1 ? LOW_QUALITY : REGULAR_QUALITY)
  );

  return responsiveImageUrl && responsiveImageUrl.url
    ? responsiveImageUrl.url
    : undefined;
};

export enum ImageDisplayQuality {
  Low = 1,
  Standard = 2,
  High = 3,
}

export function getClosestImageUrl({
  containerSize,
  imageUrls,
  quality = ImageDisplayQuality.Standard,
}: {
  containerSize: number;
  imageUrls: ResponsiveImage[];
  quality?: ImageDisplayQuality;
}) {
  let index = 0;
  let currentClosest = 0;
  const target = containerSize * quality;

  for (var i = 0; i < imageUrls.length; i++) {
    const difference = Math.abs(target - imageUrls[i].width);
    if (i === 0) {
      currentClosest = difference;
    }
    if (difference < currentClosest) {
      currentClosest = difference;
      index = i;
    }
  }

  return imageUrls[index]?.urls.at(-1)?.url || '';
}
