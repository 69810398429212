export function browserUnsupported() {
  if (
    !Promise ||
    !window.fetch ||
    !Object.assign ||
    !Symbol ||
    !Array.from ||
    !URL ||
    !Map ||
    !Set ||
    !Object.defineProperties ||
    !requestAnimationFrame ||
    !String.prototype.startsWith ||
    !String.prototype.endsWith ||
    !Object.values ||
    !Intl.DateTimeFormat ||
    !String.prototype.includes ||
    !Array.prototype.includes ||
    !Array.prototype.find ||
    !Array.prototype.findIndex ||
    !window.matchMedia ||
    !String.prototype.repeat ||
    !Array.prototype.fill
  ) {
    return true;
  }

  return false;
}
