import styled from 'styled-components';

import { mediaUp, rem } from 'approot/shared/style';
import {
  spacing8,
  spacing12,
  spacing16,
  spacing20,
  spacing32,
} from 'approot/shared/styles/spacing.style';
import { grey2 } from 'approot/shared/styles/colours.style';
import { baseBoxShadowFocus } from 'approot/shared/styles/borders.style';

export const ModalCloseButtonStyled = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 100%;
  height: ${rem(spacing32)};
  width: ${rem(spacing32)};
  margin: 0;
  padding: ${rem(spacing8)};
  position: absolute;
  right: ${rem(spacing16)};
  top: ${rem(spacing12)};
  cursor: pointer;

  ${mediaUp.sm`
    right: ${rem(spacing20)};
    top: ${rem(spacing16)};
  `}

  svg {
    height: ${rem(spacing16)};
  }

  &:hover {
    background-color: ${grey2};
  }

  &:focus {
    box-shadow: ${baseBoxShadowFocus};
    outline: none;
  }
`;
