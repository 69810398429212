import { gql, makeVar } from '@apollo/client';
import { ModalKey } from 'approot/shared/modal/modal.constants';

const globalModalVar = makeVar<ModalKey>(ModalKey.NONE);

export const GET_CURRENT_MODAL = gql`
  query GetGlobalModal {
    globalModal @client
  }
`;

export const localGlobalModalTypeDefs = gql`
  extend type Query {
    globalModal: String!
  }
`;

export const getGlobalModal = () => globalModalVar();
export const setGlobalModal = (key: ModalKey) => {
  globalModalVar(key);
};
export const closeGlobalModal = () => {
  globalModalVar(ModalKey.NONE);
};
