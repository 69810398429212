import LazyRoute from 'approot/shared/routes/lazy-route';
import {
  PRIVATE_JOIN_DISPLAY_NAME,
  PRIVATE_JOIN_EMOJI,
  PRIVATE_JOIN_REFER,
  PRIVATE_JOIN_THANKS,
  PRIVATE_JOIN_ADMIN_ROLE,
  PRIVATE_JOIN_ACCOUNT_VERIFIED,
} from 'global/private-routes.constants';

export default [
  {
    path: PRIVATE_JOIN_DISPLAY_NAME,
    component: LazyRoute(
      () => import('./join-display-name/join-display-name'),
      true
    ),
  },
  {
    path: PRIVATE_JOIN_EMOJI,
    component: LazyRoute(() => import('./join-emoji/join-emoji')),
  },
  {
    path: PRIVATE_JOIN_REFER,
    component: LazyRoute(() => import('./join-refer/join-refer')),
  },
  {
    path: PRIVATE_JOIN_THANKS,
    component: LazyRoute(() => import('./join-thank-you/join-thank-you')),
  },
  {
    path: PRIVATE_JOIN_ADMIN_ROLE,
    component: LazyRoute(() => import('./join-admin-role/join-admin-role')),
  },
  {
    path: PRIVATE_JOIN_ACCOUNT_VERIFIED,
    component: LazyRoute(() =>
      import('./join-account-verified/join-account-verified')
    ),
  },
].map(route => ({
  ...route,
}));
