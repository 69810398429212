import LazyRoute from 'approot/shared/routes/lazy-route';
import { getRedirectForOldCodeParam } from 'approot/shared/routes/redirects';
import {
  PRIVATE_INVITE,
  PRIVATE_INVITE_OLD,
} from 'global/private-routes.constants';

const InviteRoutes = [
  {
    path: PRIVATE_INVITE,
    component: LazyRoute(() => import('./invite')),
  },
  {
    path: PRIVATE_INVITE_OLD,
    component: getRedirectForOldCodeParam(PRIVATE_INVITE),
  },
];

export default InviteRoutes;
