import { ButtonHTMLAttributes, ReactNode, MouseEvent } from 'react';

import Spinner from 'approot/shared/spinner/spinner';
import { ButtonBaseProps } from './base-button.style';
import * as Styled from './primary-button.style';

type PrimaryLinkProps = {
  variant: 'link';
  to: string;
  loading?: never;
  type?: never;
  disabled?: never;
};

type PrimaryProps = {
  variant?: 'outlined' | 'default';
  to?: never;
  loading?: boolean;
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type SharedProps = {
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  id?: string;
  'data-testid'?: string;
} & ButtonBaseProps;

type PrimaryButtonProps = SharedProps & (PrimaryProps | PrimaryLinkProps);

export const PrimaryButton = ({
  children,
  loading,
  variant = 'default',
  onClick,
  to,
  disabled,
  'data-testid': dataTestId,
  ...rest
}: PrimaryButtonProps) =>
  variant === 'link' ? (
    <Styled.PrimaryButtonLink
      to={to || ''}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {children}
    </Styled.PrimaryButtonLink>
  ) : (
    <Styled.PrimaryButton
      type={rest.type ? rest.type : 'submit'}
      $variant={variant}
      $loading={loading}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
      {loading && (
        <>
          {' '}
          <Spinner />
        </>
      )}
    </Styled.PrimaryButton>
  );
