import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  cardCss,
  cardHoverTransition,
  cardHoverBoxShadow,
} from 'approot/shared/styles/cards.style';
import { rem } from 'approot/shared/style';
import { baseBoxShadowFocus } from 'approot/shared/styles/borders.style';
import { purple8, grey1 } from 'approot/shared/styles/colours.style';
import { fontSize18 } from 'approot/shared/styles/typography.style';

export const CardButtonLink = styled(Link)`
  ${cardCss}
  ${cardHoverTransition}

  background-color: transparent;
  border: 1px dashed ${purple8};
  color: ${purple8};
  display: flex;
  font-size: ${rem(fontSize18)};
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 0;
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};

  &:hover,
  &:active {
    background-color: ${grey1};
    box-shadow: ${cardHoverBoxShadow};
    color: ${purple8};
    text-decoration: underline;
  }

  &:focus {
    border-color: ${grey1};
    box-shadow: ${baseBoxShadowFocus};
    color: ${purple8};
    outline: 0;
    text-decoration: underline;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
    border: 1px dashed ${purple8};
    text-decoration: none;
  }

  &:focus-visible {
    border-color: ${grey1};
    color: ${purple8};
    box-shadow: ${baseBoxShadowFocus};
    text-decoration: underline;
  }
`;
