import styled, { css } from 'styled-components';

import { rem } from 'approot/shared/style';
import { borderRadius8 } from 'approot/shared/styles/borders.style';
import { grey4, coolGrey1 } from 'approot/shared/styles/colours.style';
import { skeletonState } from 'approot/shared/styles/transitions.style';

type ImageThumbnailProps = {
  imageUrl?: string;
  imageHasLoaded: boolean;
};

export const imageTransition = css`
  transition: opacity 400ms;
`;

export const ImageThumbnail = styled.div.attrs<ImageThumbnailProps>(
  ({ imageUrl }) => ({
    style: {
      backgroundImage: imageUrl ? `url(${imageUrl})` : '',
    },
  })
)<ImageThumbnailProps>`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${coolGrey1};
  height: 100%;
  ${imageTransition}
  opacity: ${({ imageHasLoaded }) => (imageHasLoaded ? '1' : '0')};
`;

type ImageThumbnailWrapperProps = {
  $width?: number;
  $height?: number;
};
export const ImageThumbnailWrapper = styled.div<ImageThumbnailWrapperProps>`
  background-color: ${coolGrey1};
  border-radius: ${rem(borderRadius8)};
  border: 1px solid ${grey4};
  height: ${({ $width = 56 }) => rem($width)};
  width: ${({ $width = 56 }) => rem($width)};
  flex-shrink: 0;
  overflow: hidden;
  transform: translateZ(0);
`;

type ImageStaticWrapperProps = {
  isLoading: boolean;
  isLandscape?: boolean;
};
export const ImageStaticWrapper = styled.div<ImageStaticWrapperProps>`
  border-radius: ${rem(borderRadius8)};
  flex-shrink: 0;
  min-height: ${rem(60)};
  overflow: hidden;
  transform: translateZ(0);
  width: 100%;

  ${({ isLoading }) =>
    isLoading &&
    `
      ${skeletonState}
  `};

  img {
    ${({ isLandscape = true }) =>
      isLandscape
        ? `
      width: 100%;
    `
        : `
      height: 100%;
    `}
  }
`;

export const ImageStatic = styled.img<ImageThumbnailProps>`
  ${imageTransition}
  opacity: ${({ imageHasLoaded }) => (imageHasLoaded ? '1' : '0')};
`;

type ImagePictureProps = {
  $imageHasLoaded: boolean;
};
export const ImagePicture = styled.picture<ImagePictureProps>`
  img {
    width: 100%;
  }
  ${imageTransition}
  opacity: ${({ $imageHasLoaded }) => ($imageHasLoaded ? '1' : '0')};
`;
