import { matchPath } from 'react-router-dom';

export function isUserClassHomeUrl(pathname: string) {
  const match = matchPath(
    {
      path: '/:userClassId',
    },
    pathname
  );
  return !isNaN(parseInt(match?.params?.userClassId || ''));
}
