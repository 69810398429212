import { gql } from '@apollo/client';
import { ResponsiveImageFragment } from '../responsive-images/responsive-images.graphql';

export const DELETE_LESSON = gql`
  mutation DeleteLesson($input: DeleteLessonInput!) {
    deleteLesson(input: $input)
  }
`;

export const DELETE_ASSESSMENT = gql`
  mutation DeleteAssessment($input: DeleteAssessmentInput!) {
    deleteAssessment(input: $input)
  }
`;

export const UPDATE_LESSON_INTEGRATED_SORT = gql`
  mutation UpdateLessonIntegratedSort(
    $input: UpdateLessonIntegratedSortInput!
  ) {
    updateLessonIntegratedSort(input: $input) {
      id
      integratedSort
    }
  }
`;

export const UPDATE_ASSESSMENT_INTEGRATED_SORT = gql`
  mutation UpdateAssessmentIntegratedSort(
    $input: UpdateAssessmentIntegratedSortInput!
  ) {
    updateAssessmentIntegratedSort(input: $input) {
      id
      integratedSort
    }
  }
`;

export const LessonFragment = gql`
  fragment LessonFragment on Lesson {
    id
    name
    sort
    slug
    integratedSort
    data {
      vocabularies
      englishContentArea
    }
    squareImage {
      ...ResponsiveImageFragment
    }
  }
  ${ResponsiveImageFragment}
`;

export const AssessmentFragment = gql`
  fragment AssessmentFragment on Assessment {
    id
    name
    sort
    slug
    integratedSort
    data {
      englishContentArea
    }
    squareImage {
      ...ResponsiveImageFragment
    }
  }
  ${ResponsiveImageFragment}
`;

export const materialsFragment = gql`
  fragment MaterialsFragment on StaticTeachingLearningSequenceItem {
    id
    data {
      richtext
    }
  }
`;
