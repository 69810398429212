import styled from 'styled-components';

import { rem } from 'approot/shared/style';
import { grey3, grey5, grey6 } from 'approot/shared/styles/colours.style';
import { borderRadius8 } from 'approot/shared/styles/borders.style';
import {
  fontSize16,
  fontWeightNormal,
} from 'approot/shared/styles/typography.style';
import { spacing8, spacing16 } from 'approot/shared/styles/spacing.style';

export const DisabledButtonLink = styled.a.attrs({
  'aria-disabled': true,
  role: 'link',
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${rem(40)};
  min-width: ${rem(80)};
  padding: ${rem(spacing8)} ${rem(spacing16)} ${rem(6)} ${rem(spacing16)};
  font-size: ${rem(fontSize16)};
  font-weight: ${fontWeightNormal};
  color: ${grey6} !important;
  background-color: ${grey3};
  border-radius: ${rem(borderRadius8)};
  border-color: ${grey5};
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  cursor: not-allowed;
  user-select: none;
  white-space: nowrap;
`;
