import { useCallback, useEffect, useRef } from 'react';
import { Location, useLocation, useNavigationType } from 'react-router-dom';

import { getRegionalisedLink } from 'approot/shared/logic/get-regionalised-link';
import { usePrevious } from 'approot/shared/hooks/use-previous.hooks';

type AnyObject = {
  [key: string]: any;
};

export const getScrollTop = (): number => {
  if (document.body && document.body.scrollTop) {
    return document.body.scrollTop;
  } else if (document.documentElement && document.documentElement.scrollTop) {
    return document.documentElement.scrollTop;
  }
  return 0;
};

// Feature detection code from https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
let supportsPassive = false;
try {
  const opts = Object.defineProperty({}, 'passive', {
    get: function() {
      supportsPassive = true;
      return true;
    },
  });
  window.addEventListener('test', null as any, opts);
} catch (e) {
  // ignore. exceptions can occur as part of normal code path
}
export const supportsPassiveScrolling = supportsPassive;

const stringifyLocation = (l: Location) => {
  return `/${l.pathname.replace(/^\//, '')}${
    l.search ? `?${l.search.replace(/^\?/, '')}` : ''
  }${l.hash ? `#${l.hash.replace(/^#/, '')}` : ''}`;
};

const REATTEMPT_EVERY_MILLISECONDS = 1000 / 60;
const GIVE_UP_AFTER_MILLISECONDS = 5000;

export const useScrollMemory = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  const isUserScroll = useRef<boolean>(true);
  const restoreScrollTimer = useRef<number | undefined>();
  const memory = useRef<AnyObject>({});
  const currentUrl = stringifyLocation(location);

  const previousUrl = usePrevious(getRegionalisedLink(location.pathname));

  window.addEventListener(
    'scroll',
    () => {
      const scrollTop = getScrollTop();

      const isPathChange =
        window.location.href !==
        getRegionalisedLink(
          `${location.pathname}${location.search}${location.hash}`
        );

      // prevent setting the scroll position
      // until after the location object is up to date with the browser
      if (isUserScroll.current && !isPathChange) {
        memory.current[currentUrl] = scrollTop;
      }
    },
    supportsPassiveScrolling ? { passive: true } : false
  );

  const eventuallyScrollTo = useCallback((scrollTop: number) => {
    if (restoreScrollTimer.current) {
      clearTimeout(restoreScrollTimer.current);
    }

    let remainingAttempts = Math.floor(
      GIVE_UP_AFTER_MILLISECONDS / REATTEMPT_EVERY_MILLISECONDS
    );

    const attemptScroll = () => {
      remainingAttempts--;
      isUserScroll.current = false;
      window.scrollTo(0, scrollTop);
      requestAnimationFrame(() => {
        isUserScroll.current = true;
      });
      if (remainingAttempts > 0 && getScrollTop() !== scrollTop) {
        restoreScrollTimer.current = window.setTimeout(
          attemptScroll,
          REATTEMPT_EVERY_MILLISECONDS
        );
      }
    };
    attemptScroll();
  }, []);

  useEffect(() => {
    if (navigationType === 'PUSH') {
      const isPathChange =
        previousUrl !== getRegionalisedLink(location.pathname);

      // ignore query string param changes
      if (isPathChange) {
        window.scrollTo(0, 0);
      }
    } else if (navigationType === 'POP') {
      if (memory.current[currentUrl]) {
        const scrollTop = memory.current[currentUrl];

        restoreScrollTimer.current = window.setTimeout(() => {
          eventuallyScrollTo(scrollTop);
        }, REATTEMPT_EVERY_MILLISECONDS);
      }
    }
  }, [location, previousUrl, currentUrl, navigationType, eventuallyScrollTo]);
};

export const windowOpen = (url: string) => {
  try {
    const newWindow = window.open(url);
    if (
      newWindow // because browsers can refuse to open windows
    ) {
      newWindow.opener = null; // avoid security risk. See https://mathiasbynens.github.io/rel-noopener/
    } else {
      // if no window was opened then redirect the browser
      window.location.href = url;
    }
  } catch (e) {
    // if any exception occured then redirect the browser. Might cause problems with sessionStorage but they can always back up their browser
    window.location.href = url;
  }
};
