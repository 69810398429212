import styled, { css } from 'styled-components';

import { rem } from 'approot/shared/style';
import {
  fontSize14,
  fontSize14LineHeight,
  fontWeightMedium,
} from 'approot/shared/styles/typography.style';
import {
  standardFocusStateInset,
  baseBoxShadowFocus,
  borderRadius16,
} from 'approot/shared/styles/borders.style';
import {
  coolGrey1,
  grey1,
  grey5,
  grey7,
  grey8,
  grey9,
  grey10,
} from 'approot/shared/styles/colours.style';
import {
  spacing8,
  spacing12,
  spacing20,
  MarginProps,
  marginCss,
} from 'approot/shared/styles/spacing.style';
import {
  DropdownDivider,
  DropdownItem,
} from 'approot/shared/dropdown/dropdown-item.style';
import { BadgeSmallStyled } from 'approot/shared/badge/badge.style';
import { ColourToken } from 'approot/shared/colour/colour-token.style';
import { getClassColour } from 'approot/classes/classes.utils';

export const NavigationClassesPickerMenuScrollable = styled.div`
  max-height: ${rem(337)};
  padding-bottom: ${rem(spacing8)};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

type NavigationClassesPickerMenuItemProps = {
  $isActive?: boolean;
  $colour: string;
};
export const NavigationClassesPickerMenuItem = styled(DropdownItem)<
  NavigationClassesPickerMenuItemProps
>`
  display: flex;
  align-items: center;
  height: ${rem(40)};
  padding: ${rem(spacing8)};
  white-space: normal;
  width: 100%;

  ${({ $isActive, $colour }) =>
    $isActive
      ? `
      background-color: ${getClassColour($colour).colour1};
      color: ${grey9};
      font-weight: ${fontWeightMedium};
    `
      : `
      color: ${grey8};
    `};

  &:hover,
  &:active {
    ${({ $colour }) =>
      $colour &&
      `
      background-color: ${getClassColour($colour).colour1};
    `}
    color: ${grey9};
    cursor: pointer;
  }

  ${standardFocusStateInset}

  &:focus {
    z-index: 1;
  }
`;

export const NavigationClassesToggleText = styled.span`
  display: block;
  color: ${grey9};
  font-size: ${rem(fontSize14)};
  font-weight: ${fontWeightMedium};
  line-height: ${rem(fontSize14LineHeight)};
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const baseDropdownToggle = css`
  background-color: ${grey1};
  border: 1px solid ${grey5};
  border-radius: ${rem(borderRadius16)};
  display: flex;
  align-items: center;
  padding: ${rem(spacing8)};
  position: relative;
  height: auto;
  text-align: left;
  width: ${rem(280)};

  &:hover {
    background-color: ${grey1};
    border: 1px solid ${grey5};
    cursor: pointer;

    ${NavigationClassesToggleText} {
      color: ${grey10};
    }
  }

  &:focus {
    border: 1px solid ${grey1};
    box-shadow: ${baseBoxShadowFocus};
    outline: 0;
  }
`;

export const NavigationClassesSkeleton = styled.div`
  background-color: ${coolGrey1};
  border: 1px solid ${coolGrey1};
  border-radius: ${rem(borderRadius16)};
  height: ${rem(40)};
  max-width: ${rem(280)};
  width: 100%;
`;

export const NavigationClassesToggle = styled.button.attrs({
  type: 'button',
})`
  ${baseDropdownToggle}

  & > div {
    max-width: 70%;
  }
`;

export const NavigationClassesToggleLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${rem(60)};
  white-space: nowrap;
`;

export const NavigationClassesToggleColour = styled(ColourToken)`
  margin-right: ${rem(spacing12)};
`;

export const NavigationClassesIndicator = styled.div`
  position: absolute;
  right: ${rem(spacing20)};
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${rem(10)};
  svg {
    fill: ${grey7};
    stroke: ${grey7};
    &:first-child {
      transform: rotate(180deg);
    }
  }
`;

export const NavigationClassesButtons = styled.div`
  padding-top: ${rem(spacing8)};

  button {
    width: 100%;
  }
`;

export const NavigationClassesToggleBadge = styled(BadgeSmallStyled)`
  position: absolute;
  right: ${rem(spacing8)};
`;

export const NavigationClassesDropdownDivider = styled(DropdownDivider)<
  MarginProps
>`
  ${() =>
    marginCss({
      $marginTop: 0,
      $marginBottom: 0,
    })}

    margin-left: -${rem(spacing12)};
    margin-right: -${rem(spacing12)};
`;
