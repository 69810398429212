import { Component, ReactNode } from 'react';
import { ApolloError } from '@apollo/client';
import { isFailedToFetch, isAlreadyLoggedIn } from 'approot/errors/error.utils';
import Error from 'approot/errors/error';
import FailedToFetch from 'approot/errors/failed-to-fetch';
import AlreadyLoggedIn from 'approot/errors/already-logged-in';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
  isFailedToFetch: boolean;
  isAlreadyLoggedIn: boolean;
};

export default class ApolloErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      isFailedToFetch: false,
      isAlreadyLoggedIn: false,
    };
  }

  // The existence of this function makes this an error boundary. When an
  // uncaught error is thrown it will come here and update the state
  // of this component.
  static getDerivedStateFromError(error: ApolloError) {
    if (isFailedToFetch(error)) {
      return {
        hasError: true,
        isFailedToFetch: true,
      };
    } else if (isAlreadyLoggedIn(error)) {
      return {
        hasError: true,
        isFailedToFetch: false,
        isAlreadyLoggedIn: true,
      };
    }
    throw error;
  }

  render() {
    if (this.state.hasError && this.state.isFailedToFetch) {
      return (
        <Error isHeaderless>
          <FailedToFetch />
        </Error>
      );
    } else if (this.state.hasError && this.state.isAlreadyLoggedIn) {
      return (
        <Error isHeaderless>
          <AlreadyLoggedIn />
        </Error>
      );
    }
    return this.props.children;
  }
}
