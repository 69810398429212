import { gql } from '@apollo/client';

import { ResponsiveImageFragment } from 'approot/shared/graphql/responsive-images/responsive-images.graphql';
import { SubjectFragment } from 'approot/shared/graphql/startup-data/startup-data.graphql';
import { unitStrandsFragment } from 'approot/unit/sections/strands/strands.graphql';

export const CONTENT_BROWSER = gql`
  query ContentBrowser($input: ContentBrowserInput!) {
    contentBrowser(input: $input) {
      hasContentInAllCurriculum
      cursors {
        year
        topics {
          id
        }
      }
      topics {
        label
        slug
        topicStatus
        subjects {
          ...SubjectFragment
        }
        years {
          id
          name
          label
        }
        yearsOffering
        cursorYearPosition
        units {
          unitId: id
          name
          slug
          topicId
          isIntegrated
          squareImage {
            ...ResponsiveImageFragment
          }
          unitStatus
          subjects {
            ...SubjectFragment
          }
          years {
            id
            name
          }
          unitPlannersInfo {
            plannerId
            plannerItemGroupId
            term
            plannerItems {
              id
              itemId
              itemType
            }
          }
          lessons {
            id
            name
            slug
            sort
            integratedSort
            duration
            data {
              englishContentArea
            }
            squareImage {
              ...ResponsiveImageFragment
            }
            bookmarked
            unitId
            integratedUnitId
            subjects {
              ...SubjectFragment
            }
            years {
              id
              name
            }
            lessonActivities {
              id
              status
              version
              language
            }
          }
          assessments {
            id
            name
            slug
            sort
            integratedSort
            duration
            data {
              englishContentArea
            }
            squareImage {
              ...ResponsiveImageFragment
            }
            unitId
            integratedUnitId
            bookmarked
            subjects {
              ...SubjectFragment
            }
            years {
              id
              name
            }
            assessmentActivities {
              id
              status
              version
              language
            }
          }
          ...UnitStrandsFragment
        }
      }
    }
  }
  ${ResponsiveImageFragment}
  ${SubjectFragment}
  ${unitStrandsFragment}
`;

export const REFRESH_TOPIC = gql`
  query RefreshTopic($input: QueryTopicByIdInput!) {
    topicById(input: $input) {
      id
      units {
        id
        lessons {
          id
        }
        assessments {
          id
        }
      }
    }
  }
`;

export const CONTENT_BROWSER_PAGINATION = gql`
  query ContentBrowserPagination {
    contentBrowserCursor @client
    contentBrowserCursorYear @client
  }
`;

export const paginationTypeDefs = gql`
  extend type Query {
    contentBrowserCursor: Int
    contentBrowserCursorYear: String!
  }
`;
