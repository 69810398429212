import { REGION_AU, REGION_US } from 'global/constants';

export function guessCurrentRegion() {
  const timezone = Intl.DateTimeFormat
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : undefined;
  if (timezone?.includes('America')) {
    return REGION_US;
  }
  return REGION_AU;
}
