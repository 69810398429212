import styled from 'styled-components';
import { rem, mediaDown } from 'approot/shared/style';
import { grey6, grey9 } from 'approot/shared/styles/colours.style';
import {
  fontWeightMedium,
  fontWeightLight,
  font18,
  font20,
  font56,
  PStyled,
  LinkStyled,
  AnchorStyled,
} from 'approot/shared/styles/typography.style';
import {
  LayoutCenteredContentStyled,
  LAYOUT_HEADER_HEIGHT,
} from 'approot/shared/layout/layout.styles';
import { spacing20, spacing32 } from 'approot/shared/styles/spacing.style';

export const ErrorContainerStyled = styled(LayoutCenteredContentStyled)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${LinkStyled}, ${AnchorStyled} {
    ${font18}
  }

  ${mediaDown.sm`
    padding-top: 0;
  `}
`;

type ErrorPageStyledProps = {
  isHeaderless?: boolean;
};

// display: flex to fix IE11 column flex + min-height issue
// min-height instead of height so that we can scroll larger error messages on smaller screens
export const ErrorPageStyled = styled.div<ErrorPageStyledProps>`
  display: flex;  
  ${ErrorContainerStyled} {
    min-height: calc(100vh - ${({ isHeaderless }) =>
      isHeaderless ? '0px' : `${LAYOUT_HEADER_HEIGHT}px`})};
  }
`;

export const ErrorCodeStyled = styled.div`
  color: ${grey6};
  ${font56}
  font-weight: ${fontWeightMedium};
  margin-bottom: ${rem(spacing20)};
`;

type ErrorTitleStyledProps = {
  marginBottom?: number;
};

export const ErrorTitleStyled = styled.h1<ErrorTitleStyledProps>`
  color: ${grey9};
  ${font20}
  font-weight: ${fontWeightMedium};
  text-align: center;

  ${props => props.marginBottom && `margin-bottom: ${rem(props.marginBottom)}`}
`;

export const ErrorContactStyled = styled.div`
  margin: 0 0 ${rem(spacing32)} 0;
  ${PStyled} {
    margin-bottom: 0;
    font-weight: ${fontWeightLight};
  }
`;

export const ErrorInformationStyled = styled.div`
  margin-bottom: ${rem(spacing32)};
  ${PStyled} {
    margin-bottom: 0;
  }
`;

export const ErrorInformationDetailsStyled = styled(PStyled)`
  color: ${grey6};
  margin-bottom: 0;
`;
