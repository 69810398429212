import { PUBLIC_HOME, PUBLIC_CONTACT_US } from 'global/public-routes.constants';
import QuisiStatic from 'approot/shared/quisi/quisi-static';
import { PStyled, LinkStyled } from 'approot/shared/styles/typography.style';
import {
  ErrorContainerStyled,
  ErrorCodeStyled,
  ErrorTitleStyled,
} from './errors.style';

const NotFound = () => (
  <ErrorContainerStyled>
    <ErrorCodeStyled>404</ErrorCodeStyled>
    <QuisiStatic />
    <ErrorTitleStyled>Don't worry, it's not you.</ErrorTitleStyled>
    <PStyled>
      We can't find the page you're looking for.
      <br />
      Return to <LinkStyled to={PUBLIC_HOME}>Inquisitive</LinkStyled> or{' '}
      <LinkStyled to={PUBLIC_CONTACT_US}>contact us</LinkStyled> if you require
      assistance.
    </PStyled>
  </ErrorContainerStyled>
);

export default NotFound;
