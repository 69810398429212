import { gql } from '@apollo/client';

export const GET_IMAGE_RESPONSIVE = gql`
  query GetImageResponsive($input: GetImageResponsiveInput!) {
    getImageResponsive(input: $input) {
      original
      responsive {
        width
        height
        urls {
          url
          quality
        }
      }
    }
  }
`;

export const ResponsiveImageFragment = gql`
  fragment ResponsiveImageFragment on Image {
    fileName
    responsive {
      width
      height
      urls {
        url
        quality
      }
    }
    key
    data {
      designId
      flip
    }
    cropJson
    original
  }
`;
