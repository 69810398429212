import { makeVar, gql, useQuery } from '@apollo/client';

import {
  StimulusBrowserFilters,
  StimulusBrowserFilters_stimulusBrowserFilters,
} from './__generated__/StimulusBrowserFilters';

const filtersVar = makeVar<StimulusBrowserFilters_stimulusBrowserFilters>({
  typeFilter: [],
  subjectFilter: [],
  yearFilter: [],
  page: 0,
  __typename: 'StimulusBrowserFilters',
});

export function setFilterData(
  properties: Partial<StimulusBrowserFilters_stimulusBrowserFilters>
) {
  const currentValues = filtersVar();
  const data = {
    ...currentValues,
    ...properties,
    typeFilter: properties.typeFilter || currentValues.typeFilter,
    yearFilter: properties.yearFilter || currentValues.yearFilter,
    subjectFilter: properties.subjectFilter || currentValues.subjectFilter,
    page: properties.page === undefined ? 0 : properties.page,
  };

  filtersVar(data);
  return data;
}

export function getStimulusBrowserFilters() {
  return filtersVar();
}

export const GET_STIMULUS_BROWSER_FILTERS = gql`
  query StimulusBrowserFilters {
    stimulusBrowserFilters @client {
      typeFilter
      yearFilter
      subjectFilter
      page
    }
  }
`;

export const localStimulusBrowserFiltersTypeDefs = gql`
  type StimulusBrowserFilters {
    typeFilter: [InquisitiveStimulusResourceType!]!
    yearFilter: [InquisitiveYears!]!
    subjectFilter: [InquisitiveSubject!]!
    page: Int!
  }

  extend type Query {
    stimulusBrowserFilters: StimulusBrowserFilters
  }
`;

export const useStimulusBrowserFilters = () => {
  const { data } = useQuery<StimulusBrowserFilters>(
    GET_STIMULUS_BROWSER_FILTERS
  );
  return {
    typeFilter: data?.stimulusBrowserFilters?.typeFilter || [],
    subjectFilter: data?.stimulusBrowserFilters?.subjectFilter || [],
    yearFilter: data?.stimulusBrowserFilters?.yearFilter || [],
    page: data?.stimulusBrowserFilters?.page || 0,
  };
};
