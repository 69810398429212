import styled from 'styled-components';

import {
  anchorLinkBase,
  font16,
  anchorErrorBase,
  getFontWeight,
  FontWeight,
  purpleLinkBase,
} from 'approot/shared/styles/typography.style';
import { grey6 } from 'approot/shared/styles/colours.style';

export type LinkButtonProps = {
  overrideFont?: boolean;
  error?: boolean;
  $fontWeight?: FontWeight;
};
export const LinkButton = styled.button<LinkButtonProps>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  ${({ overrideFont }) => !overrideFont && font16};
  ${({ error }) => (error ? anchorErrorBase : anchorLinkBase)};

  ${({ $fontWeight = 'normal' }) => getFontWeight($fontWeight)};
  text-decoration: underline;

  &.disabled,
  &.disabled:hover {
    color: ${grey6};
    text-decoration: none;
    cursor: not-allowed;
  }
`;

export const PrimaryLinkButton = styled.button`
  ${purpleLinkBase}

  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  padding: 0;
`;
