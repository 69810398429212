import { PUBLIC_CONTACT_US } from 'global/public-routes.constants';
import useUserData from 'approot/shared/user/user-data';
import QuisiStatic from 'approot/shared/quisi/quisi-static';
import { PStyled, AnchorStyled } from 'approot/shared/styles/typography.style';
import {
  ErrorContainerStyled,
  ErrorTitleStyled,
  ErrorContactStyled,
  ErrorInformationStyled,
  ErrorInformationDetailsStyled,
} from './errors.style';
import { getDebugInfo } from './error.utils';
import { useRegionData } from 'approot/shared/region/region.apollo';
import { getRegionalContactDetails } from 'approot/enterprise/contact/contact.utils';

const FailedToFetch = () => {
  const { user } = useUserData();
  const { region } = useRegionData();
  const { email, phone } = getRegionalContactDetails(region);

  return (
    <ErrorContainerStyled>
      <QuisiStatic />
      <ErrorTitleStyled>We're sorry, something's gone wrong</ErrorTitleStyled>
      <PStyled>Try refreshing this page.</PStyled>
      <PStyled>
        If the problem persists please try accessing Inquisitive in a different
        browser or{' '}
        <AnchorStyled href={PUBLIC_CONTACT_US}>contact us</AnchorStyled> with
        the details of your IT department.
      </PStyled>
      <ErrorContactStyled>
        <PStyled>Phone: {phone}</PStyled>
        <PStyled>Email: {email}</PStyled>
      </ErrorContactStyled>
      <ErrorInformationStyled>
        <PStyled>Please include this information with your enquiry</PStyled>
        <ErrorInformationDetailsStyled>
          {getDebugInfo()}
          {user ? `, ${user.id}` : ''}
        </ErrorInformationDetailsStyled>
      </ErrorInformationStyled>
    </ErrorContainerStyled>
  );
};

export default FailedToFetch;
