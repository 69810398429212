import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';

import { useYearTranslation } from 'approot/shared/logic/years';
import { Subject } from 'global/constants';
import { useLoggingQuery } from 'lib/apollo/apollo-hooks';
import { GET_STARTUP_DATA } from './startup-data.graphql';
import { GetStartupData } from './__generated__/GetStartupData';

export type Alias = {
  alias: string;
  paramName: Subject;
};

export const useGetStartupData = () => {
  const { data, loading, error } = useLoggingQuery<GetStartupData, null>(
    GET_STARTUP_DATA,
    undefined,
    false
  );

  const { formatYears } = useYearTranslation();

  const yearOptions = useMemo(
    () =>
      data?.startupData.years.map(year => ({
        label: formatYears([year.name]),
        value: year.name,
      })),
    [data?.startupData.years, formatYears]
  );

  const subjectOptions = useMemo(() => {
    return data?.startupData.subjects.map(subject => {
      return {
        value: subject.name,
        label: subject.label,
      };
    });
  }, [data?.startupData.subjects]);

  const curriculumOptions = useMemo(() => {
    return data?.startupData.curriculums.map(curriculum => {
      return {
        value: curriculum.name,
        label: curriculum.label,
      };
    });
  }, [data?.startupData.curriculums]);

  const subjectAliases = useMemo(() => {
    return data?.startupData.subjects.reduce((accum, curr) => {
      if (curr.urlNameAliases) {
        const items: Alias[] = curr.urlNameAliases.map(alias => {
          return {
            alias,
            paramName: curr.name,
          };
        });
        accum.push(...items);
      }

      return accum;
    }, [] as Alias[]);
  }, [data?.startupData]);

  const disciplines = useMemo(() => {
    const formattedDisciplines = data?.startupData.disciplines?.map(
      discipline => {
        const nameParts = discipline.name.split('-');
        return {
          ...discipline,
          name: discipline.name.includes('test')
            ? nameParts.splice(2, nameParts.length).join('-')
            : discipline.name,
        };
      }
    );
    return formattedDisciplines ? sortBy(formattedDisciplines, 'sort') : null;
  }, [data?.startupData.disciplines]);

  return {
    data,
    loading,
    error,
    yearOptions,
    subjectOptions,
    curriculumOptions,
    subjectAliases,
    disciplines,
  };
};

export const usePreloadStartupData = () => {
  useLoggingQuery<GetStartupData, null>(GET_STARTUP_DATA, undefined, false);
};
