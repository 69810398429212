import { css } from 'styled-components';

import { rem } from 'approot/shared/style';
import {
  borderRadius8,
  baseBoxShadowFocus,
  standardInsetBoxShadow,
} from 'approot/shared/styles/borders.style';
import {
  fontSize16,
  fontWeightNormal,
} from 'approot/shared/styles/typography.style';
import { spacing16, spacing8 } from 'approot/shared/styles/spacing.style';
import {
  grey1,
  grey3,
  grey5,
  grey6,
  grey9,
} from 'approot/shared/styles/colours.style';

export type ButtonBaseProps = {
  $isSmall?: boolean;
  $isDisabled?: boolean;
};

export type Variant = 'outlined' | 'link' | 'default';

const disabledCss = css`
  background-color: ${grey3} !important;
  border-color: ${grey5} !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
  color: ${grey6} !important;
`;

export const ButtonBaseStyled = css<ButtonBaseProps>`
  background-color: ${grey1};
  border-radius: ${rem(borderRadius8)};
  border-style: solid;
  border-width: 1px;
  border-color: ${grey5};
  color: ${grey9};
  cursor: pointer;
  display: inline-block;
  font-size: ${rem(fontSize16)};
  font-weight: ${fontWeightNormal};
  text-align: center;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  ${({ $isSmall }) =>
    $isSmall
      ? `
    height: ${rem(32)};
    padding: ${rem(4)} ${rem(spacing8)} ${rem(2)} ${rem(spacing8)};
    min-width: ${rem(48)};
  `
      : `
    height: ${rem(40)};
    min-width: ${rem(80)};
    padding: ${rem(spacing8)} ${rem(spacing16)} ${rem(6)} ${rem(spacing16)};
  `}

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    border-color: ${grey1};
    box-shadow: ${baseBoxShadowFocus};
    outline: 0;
  }

  &:active {
    background-image: none;
    ${standardInsetBoxShadow}
  }

  &:disabled,
  &.disabled {
    ${disabledCss}
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      ${disabledCss}
    `}
`;
