import { ButtonHTMLAttributes, ReactNode } from 'react';

import Spinner from 'approot/shared/spinner/spinner';
import { ButtonBaseProps } from './base-button.style';
import { NegativeButtonStyled } from './negative-button.style';

type LoadingButtonProps = {
  children: ReactNode;
  variant?: 'outlined' | 'default';
  loading?: boolean;
  onClick?: () => void;
} & ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonBaseProps;

export const NegativeButton = ({
  children,
  loading,
  variant = 'default',
  onClick,
  ...rest
}: LoadingButtonProps) => (
  <NegativeButtonStyled
    type={rest.type ? rest.type : 'submit'}
    $variant={variant}
    $loading={loading}
    onClick={onClick}
    {...rest}
  >
    {children}
    {loading && (
      <>
        {' '}
        <Spinner />
      </>
    )}
  </NegativeButtonStyled>
);
