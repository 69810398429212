import { css } from 'styled-components';

import { rem, mediaUp } from 'approot/shared/style';
import { grey1 } from 'approot/shared/styles/colours.style';
import { spacing24 } from 'approot/shared/styles/spacing.style';

export const ReactModalCss = css`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.5s;
    max-height: 100%;
    overflow-y: auto;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Content {
    max-width: ${rem(590)};
    border: none;
    width: 100%;
    margin: 5px auto;
  }

  .ReactModal__Window {
    background: ${grey1};
    border: 1px solid transparent;
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.14);
  }

  .ReactModal__ContentInner {
    padding: 0px ${rem(spacing24)};

    ${mediaUp.md`
      padding: 0px ${rem(50)};
    `}
  }
`;
