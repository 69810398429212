import { gql } from '@apollo/client';

import { schoolFragment } from 'approot/shared/graphql/school/school.graphql';
import { classFragment } from 'approot/classes/classes.graphql';

export const userEmailUpdateRequestFragment = gql`
  fragment UserEmailUpdateRequestFragment on User {
    emailUpdateRequest {
      status
      proposedEmail
    }
  }
`;

export const IS_SIGNED_IN = gql`
  query IsSignedIn {
    isSignedIn @client
    isAlreadySignedInOnce @client
    isStudentSignedIn @client
  }
`;

export const SIGN_IN_REDIRECTION_PATH = gql`
  query SignInRedirectionPath {
    signInRedirectionPath @client
  }
`;

export const SKIP_CHOOSE_CLASS_REDIRECTION_PATH = gql`
  query SkipChooseClassRedirectionPath {
    skipChooseClassRedirectionPath @client
  }
`;

export const userFragment = gql`
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
    displayName
    displayNameFormat
    emeji
    position
    title
    accountType
    accountStatus
    referralCode
    featureFlags
    intercomHash
    hasStrongPassword
    userClasses {
      ...ClassFragment
    }
    permission {
      canEditActivities
      canPublishActivities
      canBuildActivities
      canEditContent
      canManageSelfContentOnly
      canUpdatePassword
      showAccountBillingTab
      showAccountPeopleTab
      showAccountReferralTab
      showCreateClassReminder
    }
    isSchoolAdmin
    isSchoolTeacher
    isInTrial
    isFedGrantUser
    hasGoogleClassroomPermissions
    subscriptions {
      id
      plan
      status
      referralCode
    }
    school {
      ...SchoolDetailsFragment
    }
    schoolRoles
    ...UserEmailUpdateRequestFragment
    sessionAuthProvider @client
    terms {
      currentTerm
    }
  }
  ${userEmailUpdateRequestFragment}
  ${schoolFragment}
  ${classFragment}
`;

export const GET_USER = gql`
  query GetUser {
    user {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const GET_USER_TERMS = gql`
  query GetUserTerms {
    user {
      id
      terms {
        currentTerm
      }
    }
  }
`;

export const userAuthTypeDefs = gql`
  extend type Query {
    currentSessionSsoProvider: String
    isSignedIn: Boolean!
    isStudentSignedIn: Boolean!
    isAlreadySignedInOnce: Boolean!
    signInRedirectionPath: String
    skipChooseClassRedirectionPath: String
  }

  extend type User {
    sessionAuthProvider: String
  }
`;
