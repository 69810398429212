import LazyRoute from 'approot/shared/routes/lazy-route';
import {
  PUBLIC_CONTACT_US,
  PUBLIC_CONTACT_US_OLD,
} from 'global/public-routes.constants';

const ContactUsRoutes = [
  {
    path: PUBLIC_CONTACT_US,
    component: LazyRoute(() => import('approot/enterprise/contact/contact')),
  },
  {
    path: PUBLIC_CONTACT_US_OLD,
    component: LazyRoute(() => import('approot/enterprise/contact/redirects')),
  },
];

export default ContactUsRoutes;
