import styled, { createGlobalStyle } from 'styled-components';

import { fontFamilyBase, fontWeightMedium } from './typography.style';
import { mediaUp } from 'approot/shared/style';
import { ReactModalCss } from 'lib/modal/modal.style';
import { PrintCss } from './print.style';
import { RebootCss } from './reboot.style';

export const GlobalStyles = createGlobalStyle`
  html,
  body {
    font-family: ${fontFamilyBase};
    min-width: 280px;
  }

  html {
    min-height: 100%;
  }

  body {
    overflow-x: hidden;
  }

  strong {
    font-weight: ${fontWeightMedium};
  }

  ${ReactModalCss}
  ${PrintCss}
  ${RebootCss}
`;

export const ScreenReaderOnly = styled.div`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const ScreenSmallOnlyStyled = styled.span`
  display: block;
  ${mediaUp.md`
    display: none;
  `}
`;

export const ScreenMediumOnlyStyled = styled.span`
  display: none;
  ${mediaUp.md`
    display: block;
  `}
`;
