import styled, { css } from 'styled-components';

import { rem } from 'approot/shared/style';
import { grey1, grey5 } from './colours.style';
import { spacing16, spacing24, spacing32 } from './spacing.style';
import {
  standardBorderRadius,
  baseBoxShadowFocus,
  borderRadius8,
} from './borders.style';
import { HrStyled } from './decorative.style';

export const CARD_HOVER_DURATION = 200;

export const cardHoverTransition = css`
  transition: box-shadow ${CARD_HOVER_DURATION}ms;
`;

export const cardHoverBoxShadow = '0px 4px 16px rgba(0, 0, 0, 0.12)';

export const cardCss = css`
  background-color: ${grey1};
  border: solid 1px ${grey5};
  display: block;
  margin-bottom: ${rem(spacing24)};
  padding: ${rem(spacing24)};
  text-decoration: none;
  ${standardBorderRadius}
`;

export const cardStatesCss = css`
  ${cardHoverTransition};

  &:hover {
    box-shadow: ${cardHoverBoxShadow};
    text-decoration: none;
  }

  &:focus {
    box-shadow: ${baseBoxShadowFocus};
    border-color: ${grey1};
    outline: 0;
    text-decoration: none;
  }

  &:hover:focus {
    box-shadow: ${baseBoxShadowFocus}, ${cardHoverBoxShadow};
  }
`;

const FORM_CARD_PADDING_REM = rem(spacing16);

export const FormCard = styled.div<{ marginBottom?: number }>`
  border: 1px solid ${grey5};
  border-radius: ${rem(borderRadius8)};
  padding: ${FORM_CARD_PADDING_REM};
  margin-bottom: ${({ marginBottom = spacing32 }) => rem(marginBottom)};
`;

export const FormCardHr = styled(HrStyled)`
  margin: ${FORM_CARD_PADDING_REM} -${FORM_CARD_PADDING_REM};
`;

export const FormCardHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
