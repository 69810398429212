import { getTimezoneOffset } from 'date-fns-tz';

import { Regions } from 'approot/shared/region/region.constants';
import {
  INQUISITIVE_ADDRESS,
  INQUISITIVE_ADDRESS_US,
  REGION_AU,
  REGION_US,
  SUPPORT_EMAIL_AU,
  SUPPORT_EMAIL_US,
  SUPPORT_INTERNATIONAL_NUMBER,
  SUPPORT_INTERNATIONAL_NUMBER_US,
  SUPPORT_PHONE_NUMBER,
  SUPPORT_PHONE_NUMBER_US,
} from 'global/constants';

const AEST = 'AEST'; // Australian Eastern Standard Time
const AEDT = 'AEDT'; // Australian Eastern Daylight Time (Daylight Savings)
const EST = 'EST'; // US Eastern Standard Time
const EDT = 'EDT'; // US Eastern Daylight Time (Daylight Savings)

const NY_TZ = 'America/New_York';
const SYD_TZ = 'Australia/Sydney';

const CONTACT_HOURS_AU = '8:30 am to 5 pm';
const CONTACT_HOURS_US = '8 am to 5 pm';

export type Timezone = typeof AEST | typeof AEDT | typeof EST | typeof EDT;

export type Details = {
  address?: string[];
  phone?: string;
  internationalPhone?: string;
  email?: string;
  timezone?: Timezone;
  contactHrs?: string;
};

function calcUtcOffset(timezone: string, month: number) {
  const today = new Date(
    new Date().toLocaleString('en-US', { timeZone: timezone })
  );
  return getTimezoneOffset(timezone, new Date(today.getFullYear(), month, 1));
}

function isRegionInDST(timezone: string) {
  const janUtcOffset = calcUtcOffset(timezone, 0);
  const julUtcOffset = calcUtcOffset(timezone, 6);
  return Math.max(janUtcOffset, julUtcOffset) === getTimezoneOffset(timezone);
}

export function getRegionalContactDetails(region: Regions): Details {
  switch (region) {
    case REGION_AU:
      return {
        address: INQUISITIVE_ADDRESS,
        email: SUPPORT_EMAIL_AU,
        phone: SUPPORT_PHONE_NUMBER,
        internationalPhone: SUPPORT_INTERNATIONAL_NUMBER,
        timezone: isRegionInDST(SYD_TZ) ? AEDT : AEST,
        contactHrs: CONTACT_HOURS_AU,
      };
    case REGION_US:
      return {
        address: INQUISITIVE_ADDRESS_US,
        email: SUPPORT_EMAIL_US,
        phone: SUPPORT_PHONE_NUMBER_US,
        internationalPhone: SUPPORT_INTERNATIONAL_NUMBER_US,
        timezone: isRegionInDST(NY_TZ) ? EDT : EST,
        contactHrs: CONTACT_HOURS_US,
      };
    default:
      return {};
  }
}
