import { gql } from '@apollo/client';

import { SubjectFragment } from 'approot/shared/graphql/startup-data/startup-data.graphql';

export const GET_SEARCH_RESULTS = gql`
  query Search($input: SearchInput!) {
    search(input: $input) {
      totalHits
      message
      pages {
        from
        seq
        current
      }
      results {
        objectId
        bookmarked
        comingSoonMessage
        subjects {
          ...SubjectFragment
        }
        topicStatus
        topicLabel
        unitStatus
        unitName
        unitId
        overview
        unitsCount
        durationMinutes
        lessonsCount
        isStimulusAttached
        notes
        object
        description
        slug
        years
        curriculums
        totalPages
        archivedAt
        type
        score
        name
        lessonName
        squareImage
        published
        author
        link
        integratedUnitId
        guidedResearches {
          title
        }
        highlights {
          field
          matches
          object {
            href
            objectId
            type
          }
        }
        unitPlannersInfo {
          plannerId
          plannerItemGroupId
          term
          plannerItems {
            id
            itemId
            itemType
          }
        }
      }
    }
  }
  ${SubjectFragment}
`;

export const searchParamsTypeDefs = gql`
  type SearchParams {
    from: Int!
    input: String!
    subjects: [InquisitiveSubject!]!
    years: [InquisitiveYears!]!
    index: [String!]!
    isSuggestion: Boolean!
    category: String
  }

  extend type Query {
    searchParams: SearchParams
  }
`;

export const SEARCH_PARAMS = gql`
  query SearchParams {
    searchParams @client {
      input
      from
      index
      subjects
      years
      isSuggestion
      category
    }
  }
`;
