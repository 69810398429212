import { useState, useEffect, useMemo, useCallback } from 'react';
import { QueryLazyOptions } from '@apollo/client';

import ResizeObserver from 'lib/resize-observer';
import { useLoggingLazyQuery } from 'lib/apollo/apollo-hooks';
import { GET_IMAGE_RESPONSIVE } from '../graphql/responsive-images/responsive-images.graphql';
import {
  GetImageResponsive,
  GetImageResponsiveVariables,
} from '../graphql/responsive-images/__generated__/GetImageResponsive';
import { responsiveImageUrl } from './imgix-responsive';
import { ResponsiveImage } from 'approot/shared/images/imgix-responsive.constants';
import { getOriginalImageDimensions } from '../logic/get-original-image-dimensions';

export const useImageLoad = (imageUrl?: string, onLoad?: () => void) => {
  const [imageHasLoaded, setImageHasLoaded] = useState<boolean>(false);
  const [isLandscape, setIsLandscape] = useState<boolean>(false);

  useEffect(() => {
    if (imageUrl) {
      const handleLoad = () => {
        setImageHasLoaded(true);
        setIsLandscape(img.width > img.height);
        onLoad?.();
      };
      setImageHasLoaded(false);
      const img = new Image();
      img.addEventListener('load', handleLoad, false);
      img.src = imageUrl;
      return () => {
        img.removeEventListener('load', handleLoad);
      };
    } else if (imageHasLoaded) {
      setImageHasLoaded(false);
    }
    //eslint-disable-next-line
  }, [imageUrl]);

  return {
    imageHasLoaded,
    isLandscape,
  };
};

export const useImageRequestLoader = ({
  url,
  onLoad,
}: {
  url?: string | null;
  onLoad?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | undefined | null>(url);

  const [getImageResponsive] = useLoggingLazyQuery<
    GetImageResponsive,
    GetImageResponsiveVariables
  >(GET_IMAGE_RESPONSIVE, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data && data.getImageResponsive) {
        setImageUrl(data.getImageResponsive.original);
      }
    },
  });

  const { imageHasLoaded } = useImageLoad(imageUrl || '');

  useEffect(() => {
    if (imageHasLoaded && imageUrl) {
      setIsLoading(false);
      onLoad?.();
    }
  }, [imageHasLoaded, imageUrl, onLoad, setIsLoading]);

  return {
    isLoading,
    imageUrl,
    getImageResponsive: (
      options?: QueryLazyOptions<GetImageResponsiveVariables> | undefined
    ) => {
      setIsLoading(true);
      return getImageResponsive(options);
    },
    setImageUrl,
  };
};

export const useResponsiveImage = (images: ResponsiveImage[], width: number) =>
  useMemo(() => responsiveImageUrl(images, width, undefined, undefined), [
    images,
    width,
  ]);

export const useImageDimensionEffect = ({
  imageUrl,
  heightOffset = 0,
  onComplete,
}: {
  imageUrl?: string;
  heightOffset?: number;
  onComplete?: () => void;
}) => {
  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  }>();
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null
  );

  const containerWidth = containerElement?.offsetWidth || 0;
  const containerHeight = containerElement?.offsetHeight || 0;

  const computeSize = useCallback(
    (naturalWidth: number, naturalHeight: number) => {
      const aspectRatio = naturalWidth / naturalHeight;
      const maxWidth = containerWidth;
      const maxHeight = containerHeight - heightOffset;

      if (maxWidth / maxHeight > aspectRatio) {
        setImageDimensions({
          width: maxHeight * aspectRatio,
          height: maxHeight,
        });
      } else {
        setImageDimensions({
          width: maxWidth,
          height: maxWidth / aspectRatio,
        });
      }
      onComplete?.();
    },
    //eslint-disable-next-line
    [containerHeight, containerWidth, heightOffset]
  );

  const calculateImageDimensions = useCallback(async () => {
    if (imageUrl) {
      const { height, width } = await getOriginalImageDimensions({ imageUrl });

      if (height && width && containerHeight && containerWidth) {
        computeSize(width, height);
        return;
      }
    }
  }, [imageUrl, containerHeight, containerWidth, computeSize]);

  useEffect(() => {
    if (containerHeight > 0 && containerWidth > 0) {
      calculateImageDimensions();
    }
  }, [imageUrl, containerHeight, containerWidth, calculateImageDimensions]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(calculateImageDimensions);

    if (containerElement) {
      resizeObserver.observe(containerElement);
    }

    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement);
      }
    };
  }, [containerElement, calculateImageDimensions]);

  return {
    imageDimensions,
    setContainerElement,
  };
};
