import { useEffect } from 'react';

import QuisiStatic from 'approot/shared/quisi/quisi-static';
import { PStyled } from 'approot/shared/styles/typography.style';
import { PrimaryButton } from 'approot/shared/styles/buttons';
import { signOut } from 'approot/shared/signin/signin.utils';
import { pushDataTrackerEvent } from 'approot/shared/data-tracker/data-tracker-push';
import {
  EVENT_ACTION_SESSION_TAKEN,
  EVENT_CATEGORY_AUTHENTICATION,
  EVENT_TARGET_TYPE_TEACHER,
} from 'approot/shared/data-tracker/data-events.constants';
import { getUserId } from 'approot/shared/api/auth/auth';
import { ErrorContainerStyled, ErrorTitleStyled } from './errors.style';

const AlreadyLoggedIn = () => {
  useEffect(() => {
    pushDataTrackerEvent(
      EVENT_CATEGORY_AUTHENTICATION,
      EVENT_ACTION_SESSION_TAKEN,
      {
        target_type: EVENT_TARGET_TYPE_TEACHER,
        target_identifier: getUserId(),
      }
    );
  }, []);

  return (
    <ErrorContainerStyled>
      <QuisiStatic />
      <ErrorTitleStyled>We're sorry</ErrorTitleStyled>
      <PStyled>
        An account is already logged in from this computer, please sign out to
        continue.
      </PStyled>
      <PrimaryButton
        onClick={() => {
          signOut();
          window.location.reload();
        }}
      >
        Sign Out
      </PrimaryButton>
    </ErrorContainerStyled>
  );
};

export default AlreadyLoggedIn;
