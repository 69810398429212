import LazyRoute from 'approot/shared/routes/lazy-route';
import {
  PRIVATE_ACCOUNT_SCHOOL_JOIN_1,
  PRIVATE_ACCOUNT_SCHOOL_JOIN_2,
} from 'global/private-routes.constants';

const SchoolRoutes = [
  {
    path: [PRIVATE_ACCOUNT_SCHOOL_JOIN_1, PRIVATE_ACCOUNT_SCHOOL_JOIN_2],
    component: LazyRoute(() => import('./invited-teacher')),
  },
];

export default SchoolRoutes;
