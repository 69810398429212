import { PUBLIC_CONTACT_US } from 'global/public-routes.constants';
import QuisiStatic from 'approot/shared/quisi/quisi-static';
import { PStyled, LinkStyled } from 'approot/shared/styles/typography.style';
import {
  ErrorContainerStyled,
  ErrorCodeStyled,
  ErrorTitleStyled,
} from './errors.style';

const ServerError = () => (
  <ErrorContainerStyled>
    <ErrorCodeStyled>500</ErrorCodeStyled>
    <QuisiStatic />
    <ErrorTitleStyled>Don't worry, it's not you.</ErrorTitleStyled>
    <PStyled>
      Our servers say something is wrong. Try again later or{' '}
      <LinkStyled to={PUBLIC_CONTACT_US}>contact us</LinkStyled> if you require
      assistance.
    </PStyled>
  </ErrorContainerStyled>
);

export default ServerError;
