import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import {
  grey1,
  grey2,
  grey4,
  grey6,
  grey7,
  grey9,
  purple1,
  purple6,
  purple7,
  purple8,
} from 'approot/shared/styles/colours.style';
import { rem } from 'approot/shared/style';
import {
  fontSize12,
  fontWeightNormal,
  fontSize16LineHeight,
} from 'approot/shared/styles/typography.style';
import { spacing8 } from 'approot/shared/styles/spacing.style';
import {
  ButtonBaseStyled,
  ButtonBaseProps,
} from 'approot/shared/styles/buttons/base-button.style';

export const SecondaryButtonBadgeStyled = styled.div`
  background-color: ${purple7};
  border-radius: 100%;
  color: ${grey1};
  display: inline-block;
  vertical-align: middle;
  font-size: ${rem(fontSize12)};
  font-weight: ${fontWeightNormal};
  height: ${rem(16)};
  margin-right: ${rem(spacing8)};
  padding-top: ${rem(2)};
  width: ${rem(16)};
`;

export const SecondaryBaseStyled = css`
  ${ButtonBaseStyled}

  &:hover {
    background-color: ${grey2};
    border-color: ${grey9};
    color: ${grey9};
  }

  &:focus {
    background-color: ${grey2};
    border-color: ${grey1};
    color: ${grey9};
  }

  &:active {
    background-color: ${grey2};
    border-color: ${grey9};
  }

  &:disabled,
  .disabled {
    ${SecondaryButtonBadgeStyled} {
      background-color: ${grey4};
      color: ${grey6};
    }
  }
`;

export const SecondaryButton = styled.button<
  ButtonBaseProps & {
    $withIcon?: boolean;
    $borderRadius?: number;
  }
>`
  ${SecondaryBaseStyled}

  ${({ $borderRadius }) =>
    $borderRadius !== undefined &&
    `
    border-radius: ${rem($borderRadius)};
  `}
  
  ${({ $withIcon }) =>
    $withIcon &&
    `
    display: flex;
    align-items: center;
    gap: ${rem(spacing8)};
  `}
`;

export type SecondaryButtonIconProps = {
  $noBorder?: boolean;
  $isActive?: boolean;
  $borderRadius?: number;
  $isSelected?: boolean;
  $isPlusIcon?: boolean;
};

export const SecondaryButtonIcon = styled(SecondaryButton)<
  SecondaryButtonIconProps
>`
  min-width: ${({ $isSmall }) => ($isSmall ? rem(32) : rem(40))};

  ${({ $noBorder }) =>
    $noBorder &&
    `
    border: none;
  `}

  ${({ $isActive }) =>
    $isActive &&
    `
    background-color: ${purple1};
    border-color: ${purple7};
    z-index: 1;

    &:hover {
      background-color: ${purple1};
    }

    &:focus {
      background-color: ${purple1};
    }
  `}

  ${({ $isSelected }) =>
    $isSelected &&
    `
    svg {
      stroke: ${purple7};
      fill: ${purple7};
    }
    
    &:hover {
      svg {
        fill: ${purple6};
        stroke: ${purple6};
      }
    }

    &:focus {
      svg {
        fill: ${purple7};
        stroke: ${purple7};
      }
    }

    &:active {
      border: 1px solid ${grey7};
      background-color: ${grey2};
      svg {
        fill: ${purple8};
        stroke: ${purple8};
      }
    }
  `};

   ${({ $isSelected, $isPlusIcon }) =>
     $isSelected &&
     $isPlusIcon &&
     `
      // sets the inner plus to white but keeps the outer circle purple
      path:not(:first-of-type) {
        stroke: white;
      }
    `};

  ${({ $borderRadius }) =>
    $borderRadius !== undefined &&
    `
    border-radius: ${rem($borderRadius)};
  `}

  padding-left: 0;
  padding-right: 0;
`;

export const SecondaryButtonAnchor = styled.a`
  ${SecondaryBaseStyled}
  line-height: ${rem(fontSize16LineHeight)};
  text-decoration: none;
`;

export const SecondaryButtonLink = styled(Link)<ButtonBaseProps>`
  ${SecondaryBaseStyled}

  line-height: ${rem(fontSize16LineHeight)};
  text-decoration: none;
`;

export const SecondaryButtonAndIcon = styled.button<ButtonBaseProps>`
  ${SecondaryBaseStyled}
  display: flex;
  align-items: center;

  svg {
    margin-left: ${rem(spacing8)};
  }
`;

export const SecondaryButtonIconLink = styled(Link)<
  SecondaryButtonIconProps & {
    $isSmall?: boolean;
    $hasShadow?: boolean;
  }
>`
  ${SecondaryBaseStyled}
  min-width: ${({ $isSmall }) => ($isSmall ? rem(32) : rem(40))};

  ${({ $noBorder }) =>
    $noBorder &&
    `
    border: none;
  `}

  ${({ $isActive }) =>
    $isActive &&
    `
    background-color: ${purple1};
    border-color: ${purple7};
    z-index: 1;

    &:hover {
      background-color: ${purple1};
    }

    &:focus {
      background-color: ${purple1};
    }

    & svg path {
      stroke: ${purple7};
    }
  `}

  ${({ $borderRadius }) =>
    $borderRadius !== undefined &&
    `
    border-radius: ${rem($borderRadius)};
  `}

  ${({ $hasShadow }) =>
    $hasShadow &&
    `
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 7px 8px rgba(0, 0, 0, 0.08);
  `}

  padding-left: 0;
  padding-right: 0;
`;

export const SecondaryButtonAndIconLink = styled(Link)<{
  $isSmall?: boolean;
}>`
  ${SecondaryBaseStyled}
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: ${rem(spacing8)};

  svg {
    flex-shrink: 0;
  }
`;
