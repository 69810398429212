import { FunctionComponent, SVGProps } from 'react';

export type IconProps = {
  width?: number;
  height?: number;
  rotate?: number;
};

const Icon = ({
  Svg,
  width,
  height,
  rotate,
}: IconProps & {
  Svg: FunctionComponent<SVGProps<SVGSVGElement>>;
}) => {
  return (
    <Svg
      data-selector="icon-svg"
      focusable={false}
      width={width || 16}
      height={height || 16}
      style={
        rotate
          ? {
              transform: `rotate(${rotate}deg)`,
            }
          : {}
      }
    />
  );
};

export default Icon;
