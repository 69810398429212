import styled, { css } from 'styled-components';

import { rem } from 'approot/shared/style';
import { spacing4, spacing12 } from 'approot/shared/styles/spacing.style';
import { purple1, purple7, purple9 } from 'approot/shared/styles/colours.style';
import { SecondaryBaseStyled } from './secondary-button.style';

type BaseProps = {
  $isSmall?: boolean;
  $isSelected: boolean;
  $isSquare?: boolean;
};

const baseCss = css<BaseProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  height: ${({ $isSmall }) => rem($isSmall ? 32 : 40)};

  ${({ $isSelected }) =>
    $isSelected &&
    `
    background-color: ${purple1};
    border: 1px solid ${purple7};

    &:hover, &:focus {
      color: ${purple9};
      background-color: ${purple1};
      border-color: ${purple7};
    }
  `}
`;

export const CheckableSingleButton = styled.button.attrs({
  type: 'button',
  role: 'radio',
})<BaseProps>`
  ${SecondaryBaseStyled}
  ${baseCss}
  
  min-width: ${({ $isSmall }) => rem($isSmall ? 32 : 40)};
  padding: 0 ${rem(spacing4)};
`;

export const CheckableSinglePill = styled.button.attrs({
  type: 'button',
  role: 'radio',
})<
  BaseProps & {
    $isRound?: boolean;
  }
>`
  ${SecondaryBaseStyled}
  ${baseCss}
  border-radius: ${rem(32)};

  ${({ $isRound }) =>
    $isRound
      ? `
    border-radius: ${rem(40)};
    height: ${rem(40)};
    width: ${rem(40)};
  `
      : `
    padding: 0 ${rem(spacing12)};
  `}
`;

export const CheckablePill = styled(CheckableSinglePill).attrs<BaseProps>(
  ({ $isSelected }) => ({
    role: 'checkbox',
    'aria-checked': $isSelected,
  })
)``;

export const CheckableSingleGroup = styled.div.attrs({
  role: 'radiogroup',
})`
  ${CheckableSinglePill}, ${CheckableSingleButton} {
    margin-right: ${rem(spacing12)};
  }
`;

export const CheckableGroup = styled.div`
  ${CheckablePill} {
    margin-right: ${rem(spacing12)};
  }
`;
