import 'core-js/features/symbol';
import 'core-js/es/map';
import 'core-js/features/array/flat';
import 'core-js/es/object/entries';
import 'core-js/es/object/from-entries';
import 'core-js/features/promise';
import 'core-js/features/object/from-entries';
import 'intl-pluralrules';

// fix is from https://github.com/facebook/create-react-app/issues/13118
// it can be removed when this is merged: https://github.com/facebook/create-react-app/pull/8877
export * from 'core-js/stable/array/at';

// Nodelist forEach polyfill
// @ts-ignore
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
