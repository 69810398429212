import LazyRoute from 'approot/shared/routes/lazy-route';
import { PUBLIC_JOIN } from 'global/public-routes.constants';

const joinRoutes = [
  {
    path: `${PUBLIC_JOIN}/*`,
    component: LazyRoute(() => import('./join')),
  },
];

export default joinRoutes;
