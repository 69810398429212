import queryString from 'query-string';
import { DataProxy } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client';
import { Location } from 'react-router-dom';
import { TFunction } from 'i18next';

import {
  SEARCH_URL_PARAM_INDEX,
  SEARCH_URL_PARAM_SUBJECTS,
  SEARCH_URL_PARAM_YEARS,
  ALL_WORD,
  ES_ALL_INDEX,
  SEARCH_URL_PARAM_INPUT,
  SEARCH_URL_PARAM_FROM,
  SEARCH_URL_PARAM_IS_SUGGESTION,
  SEARCH_URL_PARAM_TOTAL_RESULTS,
  SEARCH_URL_PARAM_CATEGORY,
  ES_TOPICS_INDEX,
  ES_UNITS_INDEX,
  ES_LESSONS_INDEX,
  ES_ASSESSMENTS_INDEX,
  ES_STIMULUS_RESOURCE_INDEX,
} from 'global/constants';
import { convert2Array } from 'lib/arrays/arrays';
import { SearchInput } from '../../../__generated__/globalTypes';
import { Search } from './__generated__/Search';
import { GET_SEARCH_RESULTS, SEARCH_PARAMS } from './search.graphql';
import {
  SearchParams,
  SearchParams_searchParams,
} from './__generated__/SearchParams';
import { DropdownListItem } from 'webclient.constants';
import {
  getFocusLabel,
  getUnitLabel,
} from 'approot/shared/logic/content-label';

export const getSearchParamsFromUrl = (location: Location) => {
  const params = queryString.parse(location.search);

  let from = params[SEARCH_URL_PARAM_FROM];
  from = parseInt(Array.isArray(from) ? from[0] : from, 10);
  const input = params[SEARCH_URL_PARAM_INPUT] || '';
  const category = params[SEARCH_URL_PARAM_CATEGORY];
  const subjects = convert2Array(params[SEARCH_URL_PARAM_SUBJECTS]);
  const years = convert2Array(params[SEARCH_URL_PARAM_YEARS]);
  const index = convert2Array(params[SEARCH_URL_PARAM_INDEX]);

  return {
    subjects:
      subjects.length === 0 || subjects.includes(ALL_WORD) ? [] : subjects,
    years: years.length === 0 || years.includes(ALL_WORD) ? [] : years,
    index: index.length === 0 || index.includes(ES_ALL_INDEX) ? [] : index,
    input: Array.isArray(input) ? input[0] : input,
    from: from || 0,
    category,
  };
};

export const getTrackerParamsFromUrl = () => {
  const params = queryString.parse(window.location.search);

  return {
    isSuggestion: params[SEARCH_URL_PARAM_IS_SUGGESTION] === '1',
    totalHits: params[SEARCH_URL_PARAM_TOTAL_RESULTS],
  };
};

export function getSearchParamsFromCachedData(
  searchParams?: SearchParams_searchParams | null
): SearchInput | undefined {
  if (searchParams) {
    const { isSuggestion, __typename, ...rest } = searchParams;
    return rest;
  }

  return undefined;
}

export const stringifySearchParams = <T extends SearchInput>({
  subjects,
  years,
  index,
  from,
  input,
  category,
}: T) => {
  return queryString.stringify({
    [SEARCH_URL_PARAM_INDEX]: index || undefined,
    [SEARCH_URL_PARAM_YEARS]: years || undefined,
    [SEARCH_URL_PARAM_SUBJECTS]: subjects || undefined,
    [SEARCH_URL_PARAM_FROM]: from || 0,
    [SEARCH_URL_PARAM_INPUT]: input || undefined,
    [SEARCH_URL_PARAM_CATEGORY]: category || '',
  });
};

export const addTrackerParams = (
  urlParams: string,
  totalHits?: number,
  isSuggestion?: boolean
) => {
  const newParams = queryString.stringify({
    [SEARCH_URL_PARAM_TOTAL_RESULTS]: totalHits,
    [SEARCH_URL_PARAM_IS_SUGGESTION]: isSuggestion ? 1 : 0,
  });
  return `${urlParams}&${newParams}`;
};

export function updateSearchCacheResults(
  client: DataProxy,
  result: { id: number; [key: string]: any },
  searchParams: SearchInput,
  classId?: number
) {
  const variables = {
    input: {
      ...searchParams,
      classId,
    },
  };
  const data = client.readQuery<Search>({
    query: GET_SEARCH_RESULTS,
    variables: variables,
  });

  if (data?.search) {
    const updatedResults = [...data.search.results];

    for (let i = 0; i < updatedResults.length; i++) {
      if (updatedResults[i].objectId === result.id) {
        const { id, ...rest } = result;
        updatedResults[i] = {
          ...updatedResults[i],
          ...rest,
        };

        break;
      }
    }

    client.writeQuery({
      query: GET_SEARCH_RESULTS,
      data: {
        search: {
          ...data.search,
          results: updatedResults,
        },
      },
      variables: variables,
    });
  }
}

export function updateSearchParamsInCache(
  client: ApolloClient<object>,
  searchParams?: Omit<SearchParams_searchParams, '__typename'>
) {
  client.writeQuery<SearchParams>({
    query: SEARCH_PARAMS,
    data: {
      searchParams: searchParams
        ? {
            ...searchParams,
            __typename: 'SearchParams',
          }
        : null,
    },
  });
}

export function getResultTypes(t: TFunction) {
  const resultTypes: DropdownListItem[] = [
    {
      value: ALL_WORD,
      label: 'All Results',
      index: 0,
    },
    {
      value: ES_TOPICS_INDEX,
      label: getFocusLabel(t, { plural: true }),
      index: 1,
    },
    {
      value: ES_UNITS_INDEX,
      label: getUnitLabel(t, { plural: true }),
      index: 2,
    },
    {
      value: ES_LESSONS_INDEX,
      label: 'Lessons',
      index: 3,
    },
    {
      value: ES_ASSESSMENTS_INDEX,
      label: 'Assessments',
      index: 4,
    },
    {
      value: ES_STIMULUS_RESOURCE_INDEX,
      label: 'Stimulus Resources',
      index: 5,
    },
  ];

  return resultTypes;
}
