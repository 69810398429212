import cloneDeep from 'lodash/cloneDeep';

import {
  USER_DATA_KEY,
  StorageUserData,
  ALREADY_SIGNED_IN_KEY,
} from './auth.constants';
import { logException } from 'approot/shared/debug';
import {
  localStorageWrapper,
  sessionStorageWrapper,
} from 'approot/shared/storage';
import { RememberType } from 'approot/shared/signin/signin.constants';

export const parseUserData = (
  userDataString: string,
  remember: RememberType
): StorageUserData | undefined => {
  let userData;
  if (userDataString) {
    try {
      userData = JSON.parse(userDataString);
    } catch (e) {
      // Ignore corrupted user data, forcing them to Sign In again
      logException({
        sourceFile: 'auth.tsx',
        message: `Invalid user data: ${userDataString}`,
        e,
      });
    }
  }
  // The string 'null' has sometimes been stored in localStorage which breaks
  // resuming sessions so this now does a basic sanity check on whether the
  // user data appears valid (ie, whether it has 'token' property)
  if (userData && userData.token) {
    return {
      ...userData,
      remember,
    };
  }
  // eslint-disable-next-line consistent-return
  return undefined;
};

let localUserData: StorageUserData | undefined;

export const getUserData = (): StorageUserData | undefined => {
  // FIXME: Cache this in memory, don't access disk every time
  let userData,
    remember: RememberType = RememberType.DontPersist;

  const localStorageData = localStorageWrapper.getItem(USER_DATA_KEY);
  const sessionStorageData = sessionStorageWrapper.getItem(USER_DATA_KEY);

  if (localStorageData && sessionStorageData) {
    // this can happen when two tabs login, one with remember me checked
    // prefer the remember me login and refresh
    sessionStorageWrapper.removeItem(USER_DATA_KEY);
    refreshPage();
  }

  if (localStorageData) {
    remember = RememberType.LocalStorage;
    userData = parseUserData(localStorageData, remember);
  } else if (sessionStorageData) {
    remember = RememberType.SessionStorage;
    userData = parseUserData(sessionStorageData, remember);
  }

  if (!userData && localUserData) {
    // this can happen if another tab logs out a remember me login
    refreshPage();
  }

  if (userData && localUserData) {
    if (userData.token !== localUserData.token) {
      // this can happen when two tabs login with remember me with different users
      refreshPage();
    }
  }

  return userData || localUserData;
};

export const getToken = () => {
  const userData = getUserData();
  return userData && userData.token;
};
export const isSignedIn = () => !!getToken();
export const getUserId = () => {
  const data = getUserData();
  return data && data.user && data.user.id;
};

export const setUserData = (
  newUserData?: StorageUserData,
  remember?: RememberType
) => {
  if (!newUserData) return;

  const data = cloneDeep(newUserData);

  // For now keep the class list empty in localStorage
  if (data.user.userClasses) {
    data.user.userClasses = null;
  }

  localUserData = data;

  const userDataString = JSON.stringify(data);
  if (remember === RememberType.LocalStorage) {
    localStorageWrapper.setItem(USER_DATA_KEY, userDataString);
    sessionStorageWrapper.removeItem(USER_DATA_KEY);
  } else {
    localStorageWrapper.removeItem(USER_DATA_KEY);
    sessionStorageWrapper.setItem(USER_DATA_KEY, userDataString);
  }
};

export const updateUserData = (update: Partial<StorageUserData['user']>) => {
  const userData = getUserData();
  if (userData && userData.user) {
    userData.user = {
      ...userData.user,
      ...update,
    };
    localUserData = userData;
    setUserData(userData, userData.remember);
  }
};

export const clearUserData = () => {
  localUserData = undefined;
  localStorageWrapper.removeItem(USER_DATA_KEY);
  sessionStorageWrapper.removeItem(USER_DATA_KEY);
};

export const clearHistoryState = () => {
  const keys = Object.keys(window.sessionStorage);
  for (let key in keys) {
    if (keys[key].startsWith('@@History/')) {
      // TODO: when react-router is upgraded to v5, check this still works
      sessionStorageWrapper.removeItem(keys[key]);
    }
  }
};

export const updateAlreadySignedInOnce = (): void => {
  return localStorageWrapper.setItem(
    ALREADY_SIGNED_IN_KEY,
    true.toString(),
    'updateAlreadySignedInOnce'
  );
};

export const getAlreadySignedInOnce = (): boolean => {
  const oldAlreadySignedIn = localStorageWrapper.getItem('alreadySignedIn');
  // assign the old already signed in value to the new key
  if (oldAlreadySignedIn) {
    localStorageWrapper.setItem(ALREADY_SIGNED_IN_KEY, oldAlreadySignedIn);
    localStorageWrapper.removeItem('alreadySignedIn');
  }

  return (
    localStorageWrapper.getItem(
      ALREADY_SIGNED_IN_KEY,
      'getAlreadySignedInOnce'
    ) === true.toString()
  );
};

const refreshPage = () => {
  window.location.reload();
};
