import { ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useCheckAuthBeforeMount from 'approot/shared/auth/use-check-auth-before-mount';
import NotFound from 'approot/errors/not-found';
import { removeSplashLoading } from 'approot/shared/layout/layout.hooks';
import { hideIntercomLauncher } from 'lib/intercom/intercom';

type Props = {
  isAuthenticatedRoute: boolean;
  isStudentRoute: boolean;
  children: ReactNode;
  featureFlag?: string;
};

const PrivateRoute = ({
  children,
  isAuthenticatedRoute,
  isStudentRoute,
  featureFlag,
}: Props) => {
  const { isSignedIn, isStudentSignedIn } = useCheckAuthBeforeMount(
    isAuthenticatedRoute,
    isStudentRoute
  );
  const flags = useFlags();

  if (isStudentRoute) {
    hideIntercomLauncher();
  }

  if (
    isAuthenticatedRoute &&
    ((isStudentRoute && !isStudentSignedIn) || (!isStudentRoute && !isSignedIn))
  ) {
    return null;
  }

  // TODO: remove or comment out this feature flag code if not used anymore
  if (featureFlag && Object.keys(flags).length === 0) {
    // launch darkly hasn't initialised yet
    // so show nothing to prevent the not found message from flashing
    return null;
  }

  if (featureFlag && !flags[featureFlag]) {
    // the splash page is usually removed by our layouts
    // but we need to do this in order to show a 404
    removeSplashLoading();
    return <NotFound />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
