import { lazy, Suspense } from 'react';

import { REGION_AU } from 'global/constants';
import { Regions } from 'approot/shared/region/region.constants';
import PrivateRoute from './private-route';

export const LazyRouteAuthenticatedStudent = (importFn: () => Promise<any>) => {
  return LazyRoute(importFn, true, true);
};

type RouteComponentProps = {
  match: {
    params: { [key: string]: string };
    isExact: boolean;
    path: string;
    url: string;
  };
  location: Location;
  history: History;
  staticContext?: any;
};

const LazyRoute = (
  importFn: () => Promise<any>,
  authenticate: boolean = false,
  isStudentRoute: boolean = false,
  featureFlag?: string
) => {
  const LazyComponent = lazy(importFn);
  return (props: RouteComponentProps) => (
    <div>
      <Suspense fallback={''}>
        <PrivateRoute
          isAuthenticatedRoute={authenticate}
          isStudentRoute={isStudentRoute}
          featureFlag={featureFlag}
        >
          <LazyComponent {...props} />
        </PrivateRoute>
      </Suspense>
    </div>
  );
};

export default LazyRoute;

export const LazyRouteRegion = (
  options: {
    [key in Regions]: {
      importFn: () => Promise<any>;
      authenticate?: boolean;
      isStudentRoute?: boolean;
      featureFlag?: string;
    };
  }
) => {
  const region = (process.env.REACT_APP_REGION as Regions) || REGION_AU;
  return LazyRoute(
    options[region].importFn,
    !!options[region].authenticate,
    !!options[region].isStudentRoute,
    options[region].featureFlag
  );
};
