import LazyRoute from 'approot/shared/routes/lazy-route';

import { PRIVATE_TRIAL } from 'global/private-routes.constants';

const trialRoutes = [
  {
    path: `${PRIVATE_TRIAL}/*`,
    component: LazyRoute(() => import('./trial')),
  },
];

export default trialRoutes;
