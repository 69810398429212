import { useState } from 'react';

import { ResponsiveImage } from 'approot/shared/images/imgix-responsive.constants';
import { useImageLoad, useResponsiveImage } from './images.hooks';
import * as Styled from './images.style';

const DEFAULT_IMAGE_WIDTH = 56;

type Props = {
  images?: ResponsiveImage[] | null;
  width?: number;
  alt?: string;
};

export const ImageThumbnail = ({
  images,
  width = DEFAULT_IMAGE_WIDTH,
  alt,
  ...rest
}: Props) => {
  const imageUrl = useResponsiveImage(images || [], width);
  const { imageHasLoaded } = useImageLoad(imageUrl || '');
  return (
    <Styled.ImageThumbnailWrapper $width={width}>
      <Styled.ImageThumbnail
        role="img"
        aria-label={alt || undefined}
        imageHasLoaded={imageHasLoaded}
        imageUrl={imageUrl}
        {...rest}
      />
    </Styled.ImageThumbnailWrapper>
  );
};

export default ImageThumbnail;

export const ImageThumbnailStatic = ({
  imageUrl,
  width = DEFAULT_IMAGE_WIDTH,
  ...rest
}: {
  imageUrl: string;
  width?: number;
}) => {
  const { imageHasLoaded } = useImageLoad(imageUrl || '');
  return (
    <Styled.ImageThumbnailWrapper $width={width}>
      <Styled.ImageThumbnail
        imageHasLoaded={imageHasLoaded}
        imageUrl={imageUrl}
        {...rest}
      />
    </Styled.ImageThumbnailWrapper>
  );
};

type ImageStaticProps = {
  imageUrl: string;
  imageDimensions?: { width: number; height: number };
  alt?: string;
};

export const ImageStatic = ({
  imageUrl,
  imageDimensions,
  alt,
  ...rest
}: ImageStaticProps) => {
  const { imageHasLoaded } = useImageLoad(imageUrl || '');
  return (
    <Styled.ImageStaticWrapper isLoading={!imageHasLoaded}>
      <Styled.ImageStatic
        src={imageUrl}
        alt={alt}
        imageHasLoaded={imageHasLoaded}
        style={
          imageDimensions
            ? {
                width: imageDimensions.width,
                height: imageDimensions.height,
              }
            : undefined
        }
        {...rest}
      />
    </Styled.ImageStaticWrapper>
  );
};

type ImagePictureItem = {
  path: string;
};

export type ImagePictureProps = {
  avif?: ImagePictureItem;
  webp?: ImagePictureItem;
  png?: ImagePictureItem;
  jpg?: ImagePictureItem;
  lazyLoad?: boolean;
  altText?: string;
  style?: {
    width: number;
    height?: number;
  };
};

export const ImagePicture = ({
  avif,
  webp,
  png,
  jpg,
  lazyLoad,
  altText,
  style,
  ...rest
}: ImagePictureProps) => {
  const [loaded, setIsLoaded] = useState<boolean>(false);

  return (
    <Styled.ImagePicture
      $imageHasLoaded={loaded}
      onLoad={() => setIsLoaded(true)}
      {...rest}
    >
      {avif && <source srcSet={avif.path} type="image/avif" />}
      {webp && <source srcSet={webp.path} type="image/webp" />}
      {png && (
        <img
          src={png.path}
          alt={altText || ''}
          loading={lazyLoad ? 'lazy' : undefined}
          decoding="async"
          style={style}
          width={style?.width}
          height={style?.height}
        />
      )}
      {jpg && (
        <img
          src={jpg.path}
          alt={altText || ''}
          loading={lazyLoad ? 'lazy' : undefined}
          decoding="async"
          style={style}
          width={style?.width}
          height={style?.height}
        />
      )}
    </Styled.ImagePicture>
  );
};
