import { TFunction } from 'i18next';

export type FormatParams = {
  plural?: boolean;
  lower?: boolean;
};

export function getUnitLabel(
  t: TFunction,
  formatParams?: FormatParams
): string {
  const unitLabel = t('content.unitLabel', {
    count: formatParams?.plural ? 2 : 1,
  }).toString();
  return formatParams?.lower ? unitLabel.toLowerCase() : unitLabel;
}

export function getUnitProgramLabel(
  t: TFunction,
  formatParams?: FormatParams
): string {
  const unitProgramLabel = t('content.unitProgramLabel', {
    count: formatParams?.plural ? 2 : 1,
  }).toString();
  return formatParams?.lower
    ? unitProgramLabel.toLowerCase()
    : unitProgramLabel;
}

export function getFocusLabel(
  t: TFunction,
  formatParams?: FormatParams
): string {
  const focusLabel = t('content.focusLabel', {
    count: formatParams?.plural ? 2 : 1,
  }).toString();
  return formatParams?.lower ? focusLabel.toLowerCase() : focusLabel;
}

export function getKeyInquiryQuestionLabel(
  t: TFunction,
  formatParams?: FormatParams
): string {
  const label = t('content.keyInquiryQuestionLabel', {
    count: formatParams?.plural ? 2 : 1,
  }).toString();
  return formatParams?.lower ? label.toLowerCase() : label;
}

export function getOutcomesLabel(
  t: TFunction,
  formatParams?: FormatParams
): string {
  const label = t('content.outcomeLabel', {
    count: formatParams?.plural ? 2 : 1,
  }).toString();
  return formatParams?.lower ? label.toLowerCase() : label;
}
