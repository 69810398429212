import { Navigate, useParams } from 'react-router-dom';

import { getPromoCodeQueryString } from 'lib/router/router.util';

export const getRedirectForOldCodeParam = (to: string) => () => {
  const params = useParams<{ code?: string }>();

  return (
    <Navigate
      to={{
        pathname: to,
        search: getPromoCodeQueryString(params.code),
      }}
    />
  );
};
