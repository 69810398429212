import { ReactNode } from 'react';

import InlineSvg from 'approot/shared/inline-svg/inline-svg';
import { ImageThumbnailStatic } from 'approot/shared/images/images';
import * as Styled from './list-button.style';

type BaseProps = {
  children: ReactNode;
  onClick?: () => void;
};

export const ListButton = ({ children, onClick, ...rest }: BaseProps) => {
  return onClick ? (
    <Styled.ListButton type="button" onClick={onClick} {...rest}>
      <Styled.ListItemDetails>{children}</Styled.ListItemDetails>
      <InlineSvg id="icon_dropdown" width={16} height={16} />
    </Styled.ListButton>
  ) : (
    <Styled.ListItem>
      <Styled.ListItemDetails>{children}</Styled.ListItemDetails>
    </Styled.ListItem>
  );
};

export const ListButtonThumbnail = ({
  children,
  imageUrl,
  onClick,
}: BaseProps & {
  imageUrl: string;
}) => {
  return (
    <ListButton onClick={onClick}>
      <Styled.ListButtonImageWrapper>
        <ImageThumbnailStatic width={48} imageUrl={imageUrl} />
      </Styled.ListButtonImageWrapper>
      {children}
    </ListButton>
  );
};
