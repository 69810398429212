import LazyRoute from 'approot/shared/routes/lazy-route';
import { PRIVATE_ACCEPT_SCHOOL_INVITE } from 'global/private-routes.constants';

const MoveSchoolRoutes = [
  {
    path: [PRIVATE_ACCEPT_SCHOOL_INVITE, `${PRIVATE_ACCEPT_SCHOOL_INVITE}/*`],
    component: LazyRoute(() => import('./move-school-account')),
  },
];

export default MoveSchoolRoutes;
