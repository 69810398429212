import {
  DataEventAttrs,
  DATA_TRACKER_EVENT_ACTION,
} from './data-tracker.constants';
import { EVENT_DATA_PAGEVIEW } from './data-events.constants';
import { dataTracker } from './data-tracker';

export function pushDataTrackerPageView(dataEventAttrs: DataEventAttrs = {}) {
  dataTracker.push({
    [DATA_TRACKER_EVENT_ACTION]: EVENT_DATA_PAGEVIEW,
    ...dataEventAttrs,
  });
}
