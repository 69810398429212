import { MouseEvent } from 'react';

import IconCircleCheck from 'approot/shared/icons/icon-circle-check';
import * as Styled from './button-radio.style';

type Props = {
  label: string;
  isActive: boolean;
  $isSmall?: boolean;
  onClick: (e: MouseEvent) => void;
};

export const ButtonRadio = ({
  label,
  isActive,
  $isSmall,
  onClick,
  ...rest
}: Props) => {
  return (
    <Styled.ButtonRadio
      type="button"
      isActive={isActive}
      $isSmall={$isSmall}
      onClick={onClick}
      role="checkbox"
      aria-checked={isActive}
      {...rest}
    >
      <IconCircleCheck width={16} height={16} />
      <span>{label}</span>
    </Styled.ButtonRadio>
  );
};

export const ButtonRadioSecondary = ({
  label,
  isActive,
  $isSmall,
  onClick,
  ...rest
}: Props) => {
  return (
    <Styled.ButtonRadioSecondary
      type="button"
      isActive={isActive}
      $isSmall={$isSmall}
      onClick={onClick}
      role="checkbox"
      aria-checked={isActive}
      {...rest}
    >
      <IconCircleCheck width={16} height={16} />
      <span>{label}</span>
    </Styled.ButtonRadioSecondary>
  );
};
