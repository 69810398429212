import {
  ACCOUNT_SCHOOL,
  AccountType,
  REGION_AU,
  REGION_US,
} from 'global/constants';
import { Regions } from 'approot/shared/region/region.constants';
import { isDevEnv } from 'approot/shared/layout/header/header.utils';
import { PricingInfo_pricing } from 'approot/shared/graphql/pricing/__generated__/PricingInfo';
import { DynamicPricingJoin_dynamicPricingJoin } from 'approot/shared/graphql/pricing/__generated__/DynamicPricingJoin';
import { BuyUser_user_billing_purchasePreview } from 'approot/account/buy/buy-plan/__generated__/BuyUser';
import { DynamicPricingAccounts_dynamicPricingAccount } from 'approot/shared/graphql/pricing/__generated__/DynamicPricingAccounts';

declare var window: GaWindow;

type GaWindow = {
  [key: string]: any;
};

export const pushEClicksDataEvent = (event: object) => {
  if (isDevEnv()) {
    console.debug('EClicks', event);
  }

  if (!window['dataLayer']) return;
  window.dataLayer.push(event);
};

export const E_CLICKS_PLAN_SELECTION = 'plan_selection';
export const E_CLICKS_LOGGED_IN = 'logged_in';
export const E_CLICKS_LOGGED_OUT = 'logged_out';
export const E_CLICKS_ORDER_SUMMARY = 'order_summary';
export const E_CLICKS_PURCHASE = 'purchase';
export const E_CLICKS_PERSONAL_DETAILS = 'personal_details';
export const E_CLICKS_EMAIL_REGISTERED = 'email_registered';
export const E_CLICKS_SCHOOL_DETAILS = 'school_details';
export const E_CLICKS_ADD_MEMBERSHIP = 'add_membership';
export const E_CLICKS_ASSIGN_TEACHERS = 'assign_teachers';
export const E_CLICKS_ADD_PAYMENT_INFO = 'add_payment_info';
export const E_CLICKS_TRIAL_CONFIRMED = 'trial_confirmed';
export const E_CLICKS_SUBSCRIPTION_CANCELLED = 'subscription_cancelled';
export const E_CLICKS_EMOJI_SELECTED = 'emoji_selected';
export const E_CLICKS_ACCOUNT_VERIFIED = 'account_verified';
export const E_CLICKS_SUCCESSFUL_REFERRAL = 'successful_referral';
export const E_CLICKS_SIGNUP = 'signup';
export const E_CLICKS_CONTINUE_INQUISITIVE = 'continue_inquisitive';
export const E_CLICKS_GO_PART_SCHOOL = 'go_part_school';
export const E_CLICKS_SCHOOL_FREE_TRIAL_UPGRADE = 'school_free_trial_upgrade';
export const E_CLICKS_CONFIRM_RENEWAL = 'confirm_renewal';
export const E_CLICKS_MANAGE_MEMBERSHIP_DOWN = 'manage_membership_down';

export const E_CLICKS_USER_FLOW_CODE_NEW = 'New';
export const E_CLICKS_USER_FLOW_CODE_BUY_MEMBERSHIPS = 'Buy Memberships';
export const E_CLICKS_USER_FLOW_CODE_REACTIVATION = 'Reactivation';
export const E_CLICKS_USER_FLOW_CODE_GO_WHOLE_SCHOOL = 'Go Whole School';
export const E_CLICKS_USER_FLOW_CODE_MANAGE_MEMBERSHIPS = 'Manage Memberships';
export const E_CLICKS_USER_FLOW_CODE_MEMBERSHIP_UP = 'Membership Up';

export const E_CLICKS_PRODUCT_NAME_PAID = 'Paid';
export const E_CLICKS_PRODUCT_NAME_FREE_TRIAL = 'Free Trial';

export type UserFlowCode =
  | typeof E_CLICKS_USER_FLOW_CODE_NEW
  | typeof E_CLICKS_USER_FLOW_CODE_BUY_MEMBERSHIPS
  | typeof E_CLICKS_USER_FLOW_CODE_REACTIVATION
  | typeof E_CLICKS_USER_FLOW_CODE_GO_WHOLE_SCHOOL
  | typeof E_CLICKS_USER_FLOW_CODE_MANAGE_MEMBERSHIPS
  | typeof E_CLICKS_USER_FLOW_CODE_MEMBERSHIP_UP;

export type ProductName =
  | typeof E_CLICKS_PRODUCT_NAME_PAID
  | typeof E_CLICKS_PRODUCT_NAME_FREE_TRIAL;

// name - depends on user inputs
export function getName(isWholeSchool: boolean, membershipNum: number) {
  return isWholeSchool
    ? 'whole-school'
    : membershipNum > 1
    ? 'part-school'
    : 'individual';
}

export const getAccountType = (
  isWholeSchool: boolean,
  accountType: AccountType
) =>
  accountType === ACCOUNT_SCHOOL
    ? isWholeSchool
      ? 'Whole School'
      : 'Part School'
    : 'Individual';

export function getProductId(
  isWholeSchool: boolean,
  pricing:
    | PricingInfo_pricing
    | DynamicPricingJoin_dynamicPricingJoin
    | BuyUser_user_billing_purchasePreview
    | DynamicPricingAccounts_dynamicPricingAccount
) {
  return isWholeSchool
    ? pricing.wholeSchoolPricePlanId
    : pricing.standardPricePlanId;
}

export function getCurrencyCode(region: Regions) {
  switch (region) {
    case REGION_AU:
      return 'AUD';
    case REGION_US:
      return 'USD';
    default:
      return 'AUD';
  }
}

export function getPlanName({
  isWholeSchool,
  accountType,
  region,
  userFlowCode,
  productName,
}: {
  isWholeSchool: boolean;
  accountType: AccountType;
  region: Regions;
  userFlowCode?: UserFlowCode;
  productName: ProductName;
}) {
  // e.g. New AU Paid Individual
  const userFlow = userFlowCode ? `${userFlowCode} ` : '';
  return `${userFlow}${region.toUpperCase()} ${productName} ${getAccountType(
    isWholeSchool,
    accountType
  )}`;
}
