import {
  INQUISITIVE_SITE_NAME,
  NEXT_INQUISITIVE_SITE_NAME,
  PREVIOUS_INQUISITIVE_SITE_NAME,
} from 'global/constants';

export function isDevEnv() {
  const domain = window.location.hostname;
  return (
    domain !== INQUISITIVE_SITE_NAME &&
    domain !== NEXT_INQUISITIVE_SITE_NAME &&
    domain !== PREVIOUS_INQUISITIVE_SITE_NAME
  );
}
