import { css } from 'styled-components';

import { rem } from 'approot/shared/style';
import {
  grey1,
  grey2,
  grey8,
  purple8,
} from 'approot/shared/styles/colours.style';
import {
  spacing8,
  spacing16,
  spacing20,
} from 'approot/shared/styles/spacing.style';
import {
  fontFamilyBase,
  fontSize16,
  fontSize22,
  fontWeightLight,
  fontWeightNormal,
} from 'approot/shared/styles/typography.style';

export const RebootCss = css`
  //
  // Reset HTML, body, and more
  //

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;

    // Changes the default tap highlight to be completely transparent in iOS.
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    // Set an unchanging font-size on root element for rem calculations
    font-size: ${fontSize16}px;
  }

  body {
    font-family: ${fontFamilyBase};
    font-weight: ${fontWeightNormal};
    line-height: 1.5;
    color: ${grey8};
    // By default, <body> has no background-color so we set one as a best practice.
    background-color: ${grey1};

    // Set responsive default-font sizes without affecting rem size calculations
    font-size: ${fontSize16}px;
  }

  // Suppress the focus outline on elements that cannot be accessed via keyboard.
  // This prevents an unwanted focus outline from appearing around elements that
  // might still respond to pointer events.
  //
  // Credit: https://github.com/suitcss/base
  [tabindex='-1']:focus {
    outline: none !important;
  }

  //
  // Typography
  //

  // Remove top margins from headings
  //
  // By default, <h1>-<h6> all receive top and bottom margins. We nuke the top
  // margin for easier control within type scales as it avoids margin collapsing.
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: ${rem(spacing8)};
    fill: ${grey8};
    font-weight: ${fontWeightLight};
  }

  // Reset margins on paragraphs
  //
  // Similarly, the top margin on <p>s get reset. However, we also reset the
  // bottom margin to use rem units instead of em.
  p {
    margin-top: 0;
    margin-bottom: ${rem(spacing20)};
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: ${rem(spacing16)};
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  blockquote {
    margin: 0 0 ${rem(spacing16)};
  }

  //
  // Links
  //

  a {
    color: inherit;
    text-decoration: underline;
    color: ${grey8};

    &:hover,
    &:focus,
    &:active {
      color: ${purple8};
    }
  }

  // And undo these styles for placeholder links/named anchors (without href)
  // which have not been made explicitly keyboard-focusable (without tabindex).
  // It would be more straightforward to just use a[href] in previous block, but that
  // causes specificity issues in many other styles that are too complex to fix.
  // See https://github.com/twbs/bootstrap/issues/19402

  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;

    @include hover-focus {
      color: inherit;
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }
  }

  //
  // Images
  //

  img {
    // By default, <img>s are inline-block. This assumes that, and vertically
    // centers them. This won't apply should you reset them to block level.
    vertical-align: middle;
    // Note: <img>s are deliberately not made responsive by default.
    // For the rationale behind this, see the comments on the .img-fluid class.
  }

  // iOS "clickable elements" fix for role="button"
  //
  // Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
  // for traditionally non-focusable elements with role="button"
  // see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

  [role='button'] {
    cursor: pointer;
  }

  // Avoid 300ms click delay on touch devices that support the touch-action CSS property.
  //
  // In particular, unlike most other browsers, IE11+Edge on Windows 10 on touch devices and IE Mobile 10-11
  // DON'T remove the click delay when <meta name="viewport" content="width=device-width"> is present.
  // However, they DO support removing the click delay via touch-action: manipulation.
  // See:
  // * https://v4-alpha.getbootstrap.com/content/reboot/#click-delay-optimization-for-touch
  // * http://caniuse.com/#feat=css-touch-action
  // * https://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay

  a,
  area,
  button,
  [role='button'],
  input,
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }

  //
  // Tables
  //

  table {
    // No longer part of Normalize since v4
    border-collapse: collapse;
    // Reset for nesting within parents with background-color.
    background-color: transparent;
  }

  th {
    // Centered by default, but left-align-ed to match the tds below.
    text-align: left;
  }

  //
  // Forms
  //

  label {
    // Allow labels to use margin for spacing.
    display: inline-block;
    margin-bottom: ${rem(spacing8)};
  }

  // Work around a Firefox/IE bug where the transparent button background
  // results in a loss of the default button focus styles.
  //
  // Credit: https://github.com/suitcss/base/
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  input,
  button,
  select,
  textarea {
    // Normalize includes font: inherit;, so font-family. font-size, etc are
    // properly inherited. However, line-height isn't inherited there.
    line-height: inherit;
    color: ${grey8};
  }

  input[type='radio'],
  input[type='checkbox'] {
    // Apply a disabled cursor for radios and checkboxes.
    //
    // Note: Neither radios nor checkboxes can be readonly.
    &:disabled {
      cursor: not-allowed;
    }
  }

  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='month'] {
    // Remove the default appearance of temporal inputs to avoid a Mobile Safari
    // bug where setting a custom line-height prevents text from being vertically
    // centered within the input.
    // See https://bugs.webkit.org/show_bug.cgi?id=139848
    // and https://github.com/twbs/bootstrap/issues/11266
    -webkit-appearance: listbox;
  }

  textarea {
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    resize: vertical;
  }

  fieldset {
    // Browsers set a default min-width: min-content; on fieldsets,
    // unlike e.g. <div>s, which have min-width: 0; by default.
    // So we reset that to ensure fieldsets behave more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359
    // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
    min-width: 0;
    // Reset the default outline behavior of fieldsets so they don't affect page layout.
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    // Reset the entire legend element to match the fieldset
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: ${rem(spacing8)};
    font-size: ${rem(fontSize22)};
    line-height: inherit;
  }

  input[type='search'] {
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // .form-control class can properly style them. Note that this cannot simply
    // be added to .form-control as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    -webkit-appearance: none;
  }

  // Always hide an element with the hidden HTML attribute (from PureCSS).
  [hidden] {
    display: none !important;
  }

  div {
    word-wrap: break-word;
    line-height: 1;
  }

  svg {
    pointer-events: none;
  }

  // Some browsers don't set this properly by default
  // see https://caniuse.com/#feat=html5semantic
  main,
  section,
  article,
  aside,
  header,
  footer,
  nav,
  figure,
  figcaption {
    display: block;
  }

  input[type='submit'],
  input[type='checkbox'] {
    cursor: pointer;
  }

  hr {
    margin-top: ${rem(spacing16)};
    margin-bottom: ${rem(spacing16)};
    border: 0;
    border-top: 1px solid ${grey2};
  }

  //accessibility enhancement, don't animate stuff if user can't take it
  //support: https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
  //explanation: https://css-tricks.com/introduction-reduced-motion-media-query/
  @media (prefers-reduced-motion: reduce) {
    #root *,
    .ReactModalPortal * {
      transition: none !important;
      animation-duration: 0.001ms !important;
      animation-iteration-count: 1 !important;
    }
  }
`;
