import {
  COLOUR_RED,
  COLOUR_VOLCANO,
  COLOUR_ORANGE,
  COLOUR_GOLD,
  COLOUR_YELLOW,
  COLOUR_LIME,
  COLOUR_GREEN,
  COLOUR_CYAN,
  COLOUR_BLUE,
  COLOUR_AZURE,
  COLOUR_PURPLE,
  COLOUR_MAGENTA,
} from 'approot/shared/colour/colour.constants';

export const CLASSES_ARCHIVE = {
  label: 'Archive',
  value: 'archive',
};
export const CLASSES_DUPLICATE = {
  label: 'Duplicate',
  value: 'duplicate',
};
export const CLASSES_SHARE = {
  label: 'Share Class',
  value: 'share-class',
};

export const CLASS_COLOURS = [
  COLOUR_RED,
  COLOUR_VOLCANO,
  COLOUR_ORANGE,
  COLOUR_GOLD,
  COLOUR_YELLOW,
  COLOUR_LIME,
  COLOUR_GREEN,
  COLOUR_CYAN,
  COLOUR_BLUE,
  COLOUR_AZURE,
  COLOUR_PURPLE,
  COLOUR_MAGENTA,
];
