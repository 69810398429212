import LazyRoute from 'approot/shared/routes/lazy-route';
import {
  PRIVATE_ASSESSMENT,
  PRIVATE_CLASSES_PARAM,
  PRIVATE_LESSON,
  PRIVATE_TOPIC,
  PRIVATE_UNIT,
  PRIVATE_UNIT_OLD,
} from 'global/private-routes.constants';
import {
  PUBLIC_ASSESSMENT,
  PUBLIC_LESSON,
  PUBLIC_TOPIC,
  PUBLIC_UNIT,
  PUBLIC_UNIT_OLD,
} from 'global/public-routes.constants';

export const OLD_TOPIC_1 = '/topic/:topicId';
export const OLD_TOPIC_2 = '/f/:topicId';
export const OLD_TOPIC_1_PRIVATE = `${PRIVATE_CLASSES_PARAM}${OLD_TOPIC_1}`;
export const OLD_TOPIC_2_PRIVATE = `${PRIVATE_CLASSES_PARAM}${OLD_TOPIC_2}`;
export const OLD_TOPIC_1_EDIT = `${OLD_TOPIC_1_PRIVATE}/edit`;
export const OLD_TOPIC_2_EDIT = `${OLD_TOPIC_2_PRIVATE}/edit`;

const contentPageRoutes = [
  {
    path: [PUBLIC_LESSON],
    component: LazyRoute(() => import('approot/lesson/lesson-guest')),
  },
  {
    path: [PRIVATE_LESSON],
    component: LazyRoute(() => import('approot/lesson/lesson')),
  },
  {
    path: `${PRIVATE_LESSON}/edit`,
    component: LazyRoute(() => import('approot/lesson/lesson-edit')),
  },
  {
    path: [PUBLIC_ASSESSMENT],
    component: LazyRoute(() => import('approot/assessment/assessment-guest')),
  },
  {
    path: [PRIVATE_ASSESSMENT],
    component: LazyRoute(() => import('approot/assessment/assessment')),
  },
  {
    path: `${PRIVATE_ASSESSMENT}/edit`,
    component: LazyRoute(() => import('approot/assessment/assessment-edit')),
  },
  {
    path: [`${PUBLIC_UNIT}/edit`, `${PRIVATE_UNIT}/edit`],
    component: LazyRoute(() => import('approot/unit/unit-edit')),
  },
  {
    path: [PUBLIC_UNIT, PRIVATE_UNIT],
    component: LazyRoute(() => import('approot/unit/unit')),
  },
  {
    path: PUBLIC_UNIT_OLD,
    component: LazyRoute(() => import('approot/unit/unit-redirects')),
  },
  {
    path: PRIVATE_UNIT_OLD,
    component: LazyRoute(() => import('approot/unit/unit-redirects-private')),
  },
  {
    path: [PUBLIC_TOPIC, PRIVATE_TOPIC],
    component: LazyRoute(() => import('approot/topic/topic')),
  },
  {
    path: [`${PUBLIC_TOPIC}/edit`, `${PRIVATE_TOPIC}/edit`],
    component: LazyRoute(() => import('approot/topic/topic-edit')),
  },
  {
    path: [
      OLD_TOPIC_1_EDIT,
      OLD_TOPIC_2_EDIT,
      OLD_TOPIC_1_PRIVATE,
      OLD_TOPIC_2_PRIVATE,
    ],
    component: LazyRoute(() => import('approot/topic/topic-redirects-private')),
  },
  {
    path: [OLD_TOPIC_1, OLD_TOPIC_2],
    component: LazyRoute(() => import('approot/topic/topic-redirects')),
  },
];

export default contentPageRoutes;
