export async function getOriginalImageDimensions({
  imageUrl,
}: {
  imageUrl: string;
}): Promise<{
  width: number;
  height: number;
}> {
  return new Promise(resolve => {
    const img = new Image();
    img.src = imageUrl;

    if (img.naturalWidth && img.naturalHeight) {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    }

    img.onload = () => {
      const { naturalWidth, naturalHeight } = img;
      resolve({
        width: naturalWidth,
        height: naturalHeight,
      });
    };
  });
}
