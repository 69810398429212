import {
  EVENT_ACTION_EXCEPTION,
  EVENT_ACTION_MESSAGE,
  EVENT_CATEGORY_ERROR,
  EVENT_TARGET_TYPE_APPLICATION_ERROR,
} from 'approot/shared/data-tracker/data-events.constants';
import { pushDataTrackerEvent } from 'approot/shared/data-tracker/data-tracker-push';

declare var window: Window;

type logExceptionProps = {
  sourceFile: string;
  message: string;
  statusCode?: number;
  e?: any;
};

export const logException = ({
  sourceFile,
  message,
  statusCode,
  e,
}: logExceptionProps) => {
  console.error(message, e);
  pushDataTrackerEvent(EVENT_CATEGORY_ERROR, EVENT_ACTION_EXCEPTION, {
    target_type: EVENT_TARGET_TYPE_APPLICATION_ERROR,
    target_identifier: `${sourceFile}`,
    message: message,
    status_code: statusCode,
  });
};

let localStorageDebugMode = false;
try {
  if (window.localStorage) {
    localStorageDebugMode = !!window.localStorage.getItem('DEBUG');
  }
} catch (e) {
  logException({ sourceFile: 'debug.tsx', message: `${e}`, e });
}

type logMessageProps = {
  sourceFile: string;
  message: string;
  statusCode?: number;
};

export const logMessage = ({
  sourceFile,
  message,
  statusCode,
}: logMessageProps) => {
  console.log(message);
  pushDataTrackerEvent(EVENT_CATEGORY_ERROR, EVENT_ACTION_MESSAGE, {
    target_type: EVENT_TARGET_TYPE_APPLICATION_ERROR,
    target_identifier: `${sourceFile}`,
    message: `${message}`,
    status_code: statusCode,
  });
};

export const isDebugMode = (): boolean => {
  if (process.env.NODE_ENV !== 'production') return true;
  return localStorageDebugMode;
};
