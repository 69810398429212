import { URLSearchParams } from 'url';

import { AnyObject } from 'webclient.constants';

export function getUrlSearchParams(searchParams: URLSearchParams) {
  const params: AnyObject = {};
  for (const key of searchParams.keys()) {
    // return values as an array if there are multiple,
    // otherwise return a single value as a string
    params[key] =
      searchParams.getAll(key).length > 1
        ? searchParams.getAll(key)
        : searchParams.get(key);
  }
  return params;
}
