import LazyRoute from 'approot/shared/routes/lazy-route';
import {
  PRIVATE_ACCOUNT_SCHOOL_SETUP_1,
  PRIVATE_ACCOUNT_SCHOOL_SETUP_2,
} from 'global/private-routes.constants';

const SchoolSetupRoutes = [
  {
    path: [PRIVATE_ACCOUNT_SCHOOL_SETUP_1, PRIVATE_ACCOUNT_SCHOOL_SETUP_2],
    component: LazyRoute(() => import('./school-setup')),
  },
];

export default SchoolSetupRoutes;
