export type RGBArray = number[];
export type RGBArrays = RGBArray[];
export type HexColour = string;
export type HexColours = HexColour[];

// Returns RGB tuplet values from 0-255

export const hexColourToRGBArray = (
  hexColour: HexColour | undefined
): RGBArray => {
  if (!hexColour) return [0, 0, 0];
  const rawColour = hexColour.replace(/^#/, '');
  const rgbWidth = rawColour.length === 3 ? 1 : 2;
  const repeatHex = rgbWidth === 1;

  return [undefined, undefined, undefined].map((v, index) => {
    const hexColour = rawColour.substr(index * rgbWidth, rgbWidth);
    return parseInt(`${hexColour}${repeatHex ? hexColour : ''}`, 16);
  });
};

export const rgbaArrayToHexColour = (rgbArray: RGBArray): HexColour => {
  return `#${rgbArray
    .map(anRGB => ('00' + anRGB.toString(16)).slice(-2))
    .join('')}`;
};

export const darken = (rgbArray: RGBArray, amount: number): RGBArray => {
  // amount should be a number between 0 - 1 that RGB is multiplied by
  // note that multiplying by a fixed number will darken bright colours
  // quicker than darker colours, and this is intentional
  return rgbArray.map(anRGB => parseInt((anRGB * amount).toString(), 10));
};

export const filterDuplicateColours = (colours: HexColours) => {
  return colours.filter((a, b) => colours.indexOf(a) === b);
};

export function toRgb(colour: string, alpha = 1) {
  return `rgba(${hexColourToRGBArray(colour).join(',')}, ${alpha})`;
}
