import {
  DataTrackerEventData,
  DATA_TRACKER_EVENT_ACTION,
  DATA_TRACKER_EVENT_CATEGORY,
  DATA_TRACKER_EVENT_DATA,
} from './data-tracker.constants';
import { dataTracker } from './data-tracker';

declare var window: GaWindow;

type GaWindow = {
  [key: string]: any;
};

// This is the main data tracking push event. When GA is retired we can remove
// the dataLayer push reference as well as the stuff in the data-layer directory.
// Keeping this function in its own files makes it easier to mock in our tests.

export const pushDataTrackerEvent = (
  eventCategory: string,
  eventAction: string,
  eventData: DataTrackerEventData
) => {
  const gaEvent = dataTracker.push({
    [DATA_TRACKER_EVENT_ACTION]: eventAction,
    [DATA_TRACKER_EVENT_CATEGORY]: eventCategory,
    [DATA_TRACKER_EVENT_DATA]: eventData,
  });

  if (!window['dataLayer']) return;
  window.dataLayer.push(gaEvent);
};
