import { createPortal } from 'react-dom';
import { useContext, useCallback, useEffect, useRef, useMemo } from 'react';

import { SnackbarNotificationStyled } from './snackbar-notification.style';
import {
  SnackbarNotificationContext,
  NotificationMode,
} from './snackbar-notification.context';

const SNACKBAR_ID = 'snackbar';

const SnackbarNotification = () => {
  const { text, displayNotification, mode } = useContext(
    SnackbarNotificationContext
  );

  const snackbarElement = useRef<HTMLDivElement | null>();

  const handleResize = useCallback(() => {
    if (snackbarElement && snackbarElement.current) {
      snackbarElement.current.style.top = `${window.innerHeight - 40}px`;
    }
  }, [snackbarElement]);

  const el = useMemo(() => document.getElementById('snackbar-root'), []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    //eslint-disable-next-line
  }, []);

  return el
    ? createPortal(
        <div role="alert" aria-live="polite">
          <SnackbarNotificationStyled
            data-selector="snackbar-message"
            ref={ref => (snackbarElement.current = ref)}
            id={SNACKBAR_ID}
            warning={mode === NotificationMode.Warning}
            error={mode === NotificationMode.Error}
            displayNotification={displayNotification}
            style={{
              top: `${window.innerHeight - 40}px`,
            }}
          >
            {text}
          </SnackbarNotificationStyled>
        </div>,
        el
      )
    : null;
};

export default SnackbarNotification;
