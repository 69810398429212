import { gql } from '@apollo/client';
import { unitDetailsFragment } from 'approot/unit/unit.graphql';

export const GET_USER_NOTIFICATIONS = gql`
  query GetUserNotifications {
    user {
      id
      accountStatus
      isSchoolTeacher
      contentReleaseNotifications {
        contentId
        contentType
        detail {
          ...UnitDetailsFragment
        }
        dismissed
      }
    }
  }
  ${unitDetailsFragment}
`;

export const DISMISS_CONTENT_RELEASE_NOTIFICATION = gql`
  mutation DimissUserContentReleaseNotification(
    $input: DismissUserContentReleaseNotificationInput!
  ) {
    dismissUserContentReleaseNotification(input: $input) {
      contentId
      contentType
      dismissed
    }
  }
`;
