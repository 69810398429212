import './polyfills';
import 'normalize.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'approot/shared/styles/fonts.css';

import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import ApolloErrorBoundary from 'apollo-error-boundary';
import { StyleSheetManager } from 'styled-components';
import { StyledTarget } from 'styled-components/dist/types';
import isPropValid from '@emotion/is-prop-valid';

import ErrorBoundary from './error-boundary';
import { client } from './apollo';
import { GlobalStyles } from 'approot/shared/styles/global.styles';
import { SwitchRouter } from 'lib/router/router';
import './internationalise';
import Tooltip from 'approot/shared/tooltip/tooltip';

const appRoot = document.getElementById('root');
const tooltip = document.getElementById('tooltip-root');

(window as any)['__ENVS'] = {
  ...['NODE_ENV', 'REACT_APP_ENVIRONMENT_NAME', 'TEST_ENV'].reduce(
    (envs, key) => {
      envs[key] = process.env[key];
      return envs;
    },
    {} as { [key: string]: string | undefined }
  ),
};

if (appRoot) {
  createRoot(appRoot).render(
    <ApolloProvider client={client.getClient()}>
      <StyleSheetManager
        enableVendorPrefixes
        shouldForwardProp={shouldForwardProp}
      >
        <GlobalStyles />
        <ErrorBoundary>
          <ApolloErrorBoundary>
            <SwitchRouter />
          </ApolloErrorBoundary>
        </ErrorBoundary>
      </StyleSheetManager>
    </ApolloProvider>
  );
} else {
  alert('Unable to start application. Please try again later.');
}

if (tooltip) {
  createRoot(tooltip).render(<Tooltip />);
}

// TODO: remove once all our SCs are using transient props
function shouldForwardProp(propName: string, target: StyledTarget<any>) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
