import styled from 'styled-components';

import {
  spacing12,
  spacing24,
  spacing32,
} from 'approot/shared/styles/spacing.style';
import { rem } from 'approot/shared/style';
import { grey4, purple1, purple9 } from './colours.style';
import { font14 } from './typography.style';

type HrProps = {
  marginTop?: number;
  marginBottom?: number;
};

export const HrStyled = styled.hr.attrs({
  'aria-hidden': true,
})<HrProps>`
  border-color: ${grey4};
  margin-top: ${({ marginTop = spacing24 }) => rem(marginTop)};
  margin-bottom: ${({ marginBottom = spacing32 }) => rem(marginBottom)};
`;

export const InlineBubble = styled.span`
  ${font14}

  display: inline-block;
  color: ${purple9};
  background-color: ${purple1};
  border-radius: ${rem(20)};
  height: ${rem(26)};
  padding: ${rem(2)} ${rem(spacing12)};
  white-space: nowrap;
`;
