import { Context } from '@apollo/client';

import { useLoggingQuery } from 'lib/apollo/apollo-hooks';
import {
  GET_PRICING_INFO,
  GET_DYNAMIC_PRICING_ACCOUNTS,
  GET_DYNAMIC_PRICING_JOIN,
  GET_DYNAMIC_PRICING_BUY_MEMBERSHIP,
} from 'approot/shared/graphql/pricing/pricing.graphql';
import { PricingInfo } from './__generated__/PricingInfo';
import {
  DynamicPricingAccounts,
  DynamicPricingAccountsVariables,
} from './__generated__/DynamicPricingAccounts';
import {
  DynamicPricingJoin,
  DynamicPricingJoinVariables,
} from './__generated__/DynamicPricingJoin';
import { GST_TAX, SALES_TAX } from 'global/constants';
import {
  DynamicPricingBuyMembership,
  DynamicPricingBuyMembershipVariables,
} from './__generated__/DynamicPricingBuyMembership';
import { DynamicPricingFragment } from './__generated__/DynamicPricingFragment';

export const usePricingInfo = () => {
  const query = useLoggingQuery<PricingInfo, null>(
    GET_PRICING_INFO,
    undefined,
    false
  );

  return query;
};

type DyanmicPricingJoinProps = {
  variables: DynamicPricingJoinVariables;
  context?: Context;
  skip?: boolean;
  onCompleted?: (data: DynamicPricingJoin) => void;
  authenticate?: boolean;
};

export const usePricingJoin = ({
  variables,
  context,
  skip,
  onCompleted,
  authenticate = false,
}: DyanmicPricingJoinProps) => {
  const query = useLoggingQuery<
    DynamicPricingJoin,
    DynamicPricingJoinVariables
  >(
    GET_DYNAMIC_PRICING_JOIN,
    {
      variables,
      context,
      fetchPolicy: 'no-cache',
      skip,
      onCompleted,
    },
    authenticate
  );

  return {
    ...query,
    ...getPricingValues(
      variables.input.isWholeSchool,
      query.data?.dynamicPricingJoin
    ),
  };
};

type DynamicPricingAccountsProps = {
  variables: DynamicPricingAccountsVariables;
  skip?: boolean;
  onCompleted?: (data: DynamicPricingAccounts) => void;
};

export const usePricingAccounts = ({
  variables,
  skip,
  onCompleted,
}: DynamicPricingAccountsProps) => {
  const query = useLoggingQuery<
    DynamicPricingAccounts,
    DynamicPricingAccountsVariables
  >(GET_DYNAMIC_PRICING_ACCOUNTS, {
    variables,
    fetchPolicy: 'no-cache',
    skip,
    onCompleted,
  });

  return {
    ...query,
    ...getPricingValues(
      variables.input.isWholeSchool,
      query.data?.dynamicPricingAccount
    ),
  };
};

type DyanmicPricingBuyProps = {
  variables: DynamicPricingBuyMembershipVariables;
  skip?: boolean;
  onCompleted?: (data: DynamicPricingBuyMembership) => void;
};

export const usePricingBuy = ({
  variables,
  skip,
  onCompleted,
}: DyanmicPricingBuyProps) => {
  const query = useLoggingQuery<
    DynamicPricingBuyMembership,
    DynamicPricingBuyMembershipVariables
  >(GET_DYNAMIC_PRICING_BUY_MEMBERSHIP, {
    variables,
    fetchPolicy: 'no-cache',
    skip,
    onCompleted,
  });

  return {
    ...query,
    ...getPricingValues(
      variables.input.isWholeSchool,
      query.data?.dynamicPricingBuyMembership
    ),
  };
};

function getPricingValues(
  isWholeSchool: boolean,
  data?: DynamicPricingFragment
) {
  const salesTax = data?.totalTax || 0;
  const creditApplied = data?.creditUsed || 0;
  const total = data?.total || 0;

  return {
    costPerTeacher:
      (isWholeSchool ? data?.wholeSchoolPrice : data?.standardPrice) || 0,
    total,
    salesTax,
    includesGst: data?.taxCategory === GST_TAX,
    includesSalesTax: data?.taxCategory === SALES_TAX && salesTax > 0,
    fee: data?.fee || 0,
    promoCodeError: data?.isValidPromoCode === false,
    percentOff: data?.percentOff,
    discountApplied: data?.discountApplied,
    creditApplied,
    creditRemaining: data?.creditRemaining || 0,
    isCreditsPurchase: creditApplied > 0 && total === 0,
  };
}
