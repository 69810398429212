import { Location } from 'react-router-dom';

import { DropdownListItem } from 'webclient.constants';
import {
  InquisitiveYears,
  InquisitiveStimulusResourceType,
  InquisitiveSubject,
} from 'global/graphql/scalars.type';
import { getLocationParams } from 'lib/router/router.util';
import {
  SearchStimulusResourcesVariables,
  SearchStimulusResources_searchStimulusResources,
} from 'approot/shared/stimulus/__generated__/SearchStimulusResources';
import { Alias } from 'approot/shared/graphql/startup-data/startup-data.hooks';

export const sanitiseValues = <T extends string>(
  paramValues: unknown[],
  filterData: T[],
  aliases?: Alias[]
) => {
  return filterData.reduce((filterValues, item) => {
    if (paramValues.includes(item)) {
      filterValues.push(item);
    } else if (aliases) {
      const alias = aliases.find(a => a.paramName === item);
      if (alias && paramValues.includes(alias.alias)) {
        filterValues.push(item);
      }
    }
    return filterValues;
  }, [] as T[]);
};

export function getBrowserFilters(
  location: Location,
  contentTypeLabels: InquisitiveStimulusResourceType[],
  subjectOptions: DropdownListItem<InquisitiveSubject>[],
  yearOptions: DropdownListItem<InquisitiveYears>[],
  subjectAliases?: Alias[]
) {
  const params = getLocationParams(location);

  let yearFilter: InquisitiveYears[] = [];
  let subjectFilter: InquisitiveSubject[] = [];
  let typeFilter: InquisitiveStimulusResourceType[] = [];

  if (params?.years) {
    yearFilter = sanitiseValues(
      !Array.isArray(params.years) ? [params.years] : params.years,
      yearOptions.map(y => y.value)
    );
  }

  if (params?.types) {
    typeFilter = sanitiseValues(
      !Array.isArray(params.types) ? [params.types] : params.years,
      contentTypeLabels
    );
  }

  if (params?.subject) {
    const list = subjectOptions.map(s => s.value) as InquisitiveSubject[];
    subjectFilter = sanitiseValues(
      !Array.isArray(params.subject) ? [params.subject] : params.subject,
      list,
      subjectAliases
    );
  }

  return {
    typeFilter,
    subjectFilter,
    yearFilter,
  };
}

export function stimulusBrowserMerge(
  existing: SearchStimulusResources_searchStimulusResources | undefined,
  incoming: SearchStimulusResources_searchStimulusResources,
  variables: SearchStimulusResourcesVariables | undefined
) {
  if (!existing) return incoming;

  // new data
  if (variables?.input.page === 0) {
    return incoming;
  }

  return {
    ...incoming,
    stimulusResources: existing.stimulusResources.concat(
      incoming.stimulusResources
    ),
  };
}

export function isEndOfContent(
  currentPage: number | undefined,
  pageSize: number | undefined,
  total: number | undefined
) {
  return ((currentPage || 0) + 1) * (pageSize || 0) >= (total || 0);
}
