import styled from 'styled-components';

import { rem } from 'approot/shared/style';
import { spacing16 } from 'approot/shared/styles/spacing.style';

type FullWidthButtonsProps = {
  marginBottom?: number;
};

export const FullWidthButtonsStyled = styled.div<FullWidthButtonsProps>`
  width: 100%;

  button,
  a {
    width: 100%;
    margin-bottom: ${({ marginBottom = spacing16 }) => rem(marginBottom)};
  }
`;
