import QuisiStatic from 'approot/shared/quisi/quisi-static';
import {
  ErrorContainerStyled,
  ErrorCodeStyled,
  ErrorTitleStyled,
} from './errors.style';
import { PStyled, LinkStyled } from 'approot/shared/styles/typography.style';
import { PUBLIC_HOME, PUBLIC_CONTACT_US } from 'global/public-routes.constants';

const UnauthorisedError = () => (
  <ErrorContainerStyled>
    <ErrorCodeStyled>401</ErrorCodeStyled>
    <QuisiStatic />
    <ErrorTitleStyled>
      You're not authorised to access this page.
    </ErrorTitleStyled>
    <PStyled>
      Return to <LinkStyled to={PUBLIC_HOME}>Inquisitive</LinkStyled> or{' '}
      <LinkStyled to={PUBLIC_CONTACT_US}>contact us</LinkStyled> if you require
      assistance.
    </PStyled>
  </ErrorContainerStyled>
);

export default UnauthorisedError;
