import styled, { css } from 'styled-components';

import { rem } from 'approot/shared/style';
import { spacing8 } from 'approot/shared/styles/spacing.style';
import {
  purple7,
  purple5,
  grey1,
  grey5,
  grey9,
} from 'approot/shared/styles/colours.style';
import { PrimaryButtonColours } from './primary-button.style';
import { SecondaryBaseStyled } from './secondary-button.style';
import { ButtonBaseStyled } from './base-button.style';

type ButtonRadioStyledProps = {
  isActive: boolean;
  $isSmall?: boolean;
};

export const ButtonRadio = styled.button<ButtonRadioStyledProps>`
  ${({ isActive }) =>
    isActive
      ? css`
          ${SecondaryBaseStyled}
        `
      : css`
          ${ButtonBaseStyled}
          ${PrimaryButtonColours}

      svg {
            stroke: ${purple5};
            fill: none;
            path:last-of-type {
              stroke: ${purple7};
            }
          }

          &:focus,
          &:hover {
            svg {
              path:last-of-type {
                stroke: ${purple5};
              }
            }
          }
        `}

  display: inline-flex;
  align-items: center;
  svg {
    margin-right: ${rem(spacing8)};
    min-width: ${rem(16)};
  }
`;

export const ButtonRadioSecondary = styled.button<ButtonRadioStyledProps>`
  ${SecondaryBaseStyled}

  ${({ isActive }) =>
    !isActive &&
    css`
      svg {
        path:first-of-type {
          stroke: ${grey5};
        }
        stroke: ${grey1};
        fill: none;
      }
      &:focus,
      &:hover {
        svg {
          path:first-of-type {
            stroke: ${grey9};
          }
          stroke: ${grey9};
        }
      }
    `}

  display: inline-flex;
  align-items: center;
  svg {
    margin-right: ${rem(spacing8)};
  }
`;
