import { gql } from '@apollo/client';

export const OutcomesFragment = gql`
  fragment OutcomesFragment on Outcome {
    id
    curriculum
    category
    code
    version
    groupLabel
    dciCode
    dciName
    name
    nameCode
    dciArrangements
  }
`;

export const SEARCH_OUTCOMES = gql`
  query SearchOutcomes($input: SearchOutcomesInput!) {
    searchOutcomes(input: $input) {
      ...OutcomesFragment
      description
      type
      groupLabel
    }
  }
  ${OutcomesFragment}
`;
