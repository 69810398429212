import { makeVar, gql } from '@apollo/client';

import { UserFragment_userClasses } from 'approot/shared/user/__generated__/UserFragment';
import { updateAnalyticsDataLayer } from 'approot/shared/analytics/data-layer';
import { classFragment } from './classes.graphql';

const currentClassVar = makeVar<UserFragment_userClasses | undefined>(
  undefined
);

export function setCurrentClass(
  userClass: UserFragment_userClasses | undefined
) {
  currentClassVar(userClass);

  if (userClass) {
    updateAnalyticsDataLayer({ classId: userClass.id });
  }
}

export function getCurrentClass() {
  return currentClassVar();
}

export const GET_CURRENT_USER_CLASS = gql`
  query GetCurrentUserClass {
    currentUserClass @client {
      ...ClassFragment
      planner {
        id
      }
    }
  }
  ${classFragment}
`;

export const localUserClassTypeDefs = gql`
  extend type Query {
    currentUserClass: UserClass
  }
`;

export function getUserClassPath(path: string) {
  const currentClass = getCurrentClass();
  return currentClass ? `/${currentClass.id}${path}` : path;
}
