import styled from 'styled-components';

import { red7, grey1, red6, grey8 } from '../colours.style';
import {
  ButtonBaseStyled,
  ButtonBaseProps,
  Variant,
} from './base-button.style';

type ButtonProps = {
  $variant?: Variant;
  $loading?: boolean;
};
export const NegativeButtonStyled = styled.button<
  ButtonProps & ButtonBaseProps
>`
  ${ButtonBaseStyled}
  border-color: ${red7};

  ${({ $variant, disabled }) =>
    $variant === 'outlined'
      ? `
    background-color: transparent;
    color: ${red7};

    &:hover {
      border-color: ${red6};
    }
    &:focus {
      color: ${red7};
    }
    &:active {
      border-color: ${red7};
      color: ${red7};
    }

    svg path {
      fill: ${disabled ? grey8 : red7}
    }
  `
      : `
    background-color: ${red7};
    color: ${grey1};

    &:hover {
      background-color: ${red6};
      border-color: ${red6};
    }
    &:focus {
      color: ${grey1};
    }
    &:active {
      background-color: ${red7};
      border-color: ${red7};
      color: ${grey1};
    }

    svg path {
      fill: ${disabled ? grey8 : grey1}
    }
  `}
`;
