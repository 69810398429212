import {
  ModalKey,
  KeepOpenOnLoadModal,
  KEEP_OPEN_ON_LOAD_MODAL_IDS,
} from './modal.constants';

export function isKeepOpenOnLoadModal(key: string): key is KeepOpenOnLoadModal {
  return KEEP_OPEN_ON_LOAD_MODAL_IDS.includes(key as KeepOpenOnLoadModal);
}

export function isModal(key: string): key is ModalKey {
  return Object.values(ModalKey).includes(key as ModalKey);
}
