import { logException } from 'approot/shared/debug';
import { sessionStorageWrapper } from 'approot/shared/storage';
import { StudentFragment } from 'approot/students/__generated__/StudentFragment';

// NOTE: this file is for the FE storage of logged in students, not class to do users

export const STUDENT_USER_DATA_KEY = `studentUserData:${process.env.REACT_APP_REGION}`;

export type StorageStudentUserData = {
  token: string;
  studentUser: StudentFragment;
  classId?: number;
  classToDoId?: string;
};

export function getStudentUserData() {
  let studentUserData: StorageStudentUserData | undefined;
  const data = sessionStorageWrapper.getItem(STUDENT_USER_DATA_KEY);

  if (data) {
    try {
      studentUserData = JSON.parse(data);
    } catch (e) {
      logException({
        sourceFile: 'auth.tsx',
        message: `Invalid student user data: ${data}`,
        e,
      });
    }
  }

  return studentUserData;
}

export function getStudentUserId() {
  const studentUserData = getStudentUserData();
  return studentUserData?.studentUser.id;
}

export function setStudentUserData(student: StorageStudentUserData) {
  const studentUserDataString = JSON.stringify(student);
  sessionStorageWrapper.setItem(STUDENT_USER_DATA_KEY, studentUserDataString);
}

export const clearStudentUserData = () => {
  sessionStorageWrapper.removeItem(STUDENT_USER_DATA_KEY);
};
