import styled from 'styled-components';

import { rem } from 'approot/shared/style';
import { spacing24 } from '../styles/spacing.style';

type QuisiStaticProps = {
  marginTop?: number;
};

export const QuisiStatic = styled.div<QuisiStaticProps>`
  margin-top: ${props => (props.marginTop ? `${rem(props.marginTop)}` : '0')};
  margin-bottom: ${rem(spacing24)};
  text-align: center;
  img {
    display: inline-block;
    max-width: ${rem(160)};
  }
`;
