import {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';

export enum NotificationMode {
  Normal,
  Warning,
  Error,
}

type NotificationOptions = { autoHide?: boolean; mode?: NotificationMode };

type SnackbarNotificationContextData = {
  displayNotification?: boolean;
  text?: string;
  mode: NotificationMode;
  setDisplayNotification: (text: string, options?: NotificationOptions) => void;
  hideNotification: () => void;
};

export const SnackbarNotificationContext = createContext<
  SnackbarNotificationContextData
>({
  displayNotification: false,
  setDisplayNotification: () => {},
  hideNotification: () => {},
  mode: NotificationMode.Normal,
});

export const SnackbarNotificationContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [displayNotification, setDisplayNotification] = useState<
    boolean | undefined
  >();
  const [text, setText] = useState<string | undefined>();
  const [mode, setMode] = useState<NotificationMode>(NotificationMode.Normal);
  const slideDownTimeout = useRef<number | undefined>();
  const displayNoneTimeout = useRef<number | undefined>();

  const hideNotification = () => {
    setDisplayNotification(false);
    displayNoneTimeout.current = window.setTimeout(() => {
      // hide the notification element
      setDisplayNotification(undefined);
    }, 1000);
  };

  const autoHide = useCallback(() => {
    slideDownTimeout.current = window.setTimeout(() => {
      hideNotification();
    }, 4000);
  }, []);

  const handleDisplayNotification = useCallback(
    (text: string, options?: NotificationOptions) => {
      if (slideDownTimeout && slideDownTimeout.current)
        clearTimeout(slideDownTimeout.current);
      if (displayNoneTimeout && displayNoneTimeout.current)
        clearTimeout(displayNoneTimeout.current);

      setText(text);
      setMode(options?.mode || NotificationMode.Normal);
      setDisplayNotification(true);

      if (options?.autoHide !== false) {
        autoHide();
      }
    },
    [slideDownTimeout, displayNoneTimeout, autoHide]
  );

  const value = useMemo(
    () => ({
      text,
      mode,
      displayNotification,
      setDisplayNotification: handleDisplayNotification,
      hideNotification,
    }),
    [displayNotification, handleDisplayNotification, text, mode]
  );

  return (
    <SnackbarNotificationContext.Provider value={value}>
      {children}
    </SnackbarNotificationContext.Provider>
  );
};
