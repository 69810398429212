import { gql } from '@apollo/client';

import { ResponsiveImageFragment } from 'approot/shared/graphql/responsive-images/responsive-images.graphql';

export const unitSquareImageFragment = gql`
  fragment UnitSquareImageFragment on Unit {
    squareImage {
      ...ResponsiveImageFragment
    }
  }
  ${ResponsiveImageFragment}
`;

export const unitLandscapeImageFragment = gql`
  fragment UnitLandscapeImageFragment on Unit {
    landscapeImage {
      ...ResponsiveImageFragment
    }
  }
  ${ResponsiveImageFragment}
`;

export const UPDATE_HERO_IMAGE = gql`
  mutation UpdateHeroImage($input: UpdateUnitHeroImageInput!) {
    updateUnitHeroImage(input: $input) {
      id
      ...UnitLandscapeImageFragment
      ...UnitSquareImageFragment
    }
  }
  ${unitSquareImageFragment}
  ${unitLandscapeImageFragment}
`;
