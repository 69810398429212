import { gql } from '@apollo/client';

export const GET_STARTUP_DATA = gql`
  query GetStartupData {
    startupData {
      years {
        id
        name
        label
      }
      subjects {
        id
        name
        label
        description
        yearsOffering
        curriculums
        order
        urlNameAliases
        colour
      }
      curriculums {
        name
        label
      }
      disciplines {
        id
        name
        label
        colour
        slug
        sort
        status
        yearsOffering
        curriculums
      }
      academicYears {
        academicYear
        startTeachingYear
        endTeachingYear
        isCurrentTeachingYear
      }
    }
  }
`;

export const SubjectFragment = gql`
  fragment SubjectFragment on Subject {
    id
    label
    name
    colour
  }
`;
