import styled from 'styled-components';

import { rem, mediaUp } from 'approot/shared/style';
import {
  orange9,
  orange1,
  red1,
  red8,
  green9,
  green1,
} from 'approot/shared/styles/colours.style';
import { font18 } from 'approot/shared/styles/typography.style';
import { standardBorderRadius } from 'approot/shared/styles/borders.style';
import {
  spacing8,
  spacing12,
  spacing16,
} from 'approot/shared/styles/spacing.style';

type Props = {
  displayNotification?: boolean;
  warning?: boolean;
  error?: boolean;
};

export const SnackbarNotificationStyled = styled.div<Props>`
  display: none;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: ${rem(spacing8)} ${rem(spacing16)};
  ${standardBorderRadius};
  box-shadow: 0px 4px 6px rgba(83, 29, 171, 0.24);
  color: ${green9};
  background-color: ${green1};
  width: calc(100% - ${rem(50)});
  max-width: ${rem(560)};
  z-index: 111; // display above modals, scroll to top, fixed footer nav

  ${font18}

  ${mediaUp.sm`
    padding: ${rem(spacing12)} ${rem(spacing16)};
    width: auto;
  `}

  ${props =>
    props.warning &&
    `
    color: ${orange9};
    background-color: ${orange1};
  `}

  ${props =>
    props.error &&
    `
    color: ${red8};
    background-color: ${red1};
  `}

  ${props =>
    props.displayNotification &&
    `
    display: block;
    animation: slideUp 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  `}

  @keyframes slideUp {
    0% {
      transform: translate(-50%, 100%);
    }
    100% {
      transform: translate(-50%, -100%);
    }
  }

  ${props =>
    props.displayNotification === false &&
    `
    display: block;
    animation: slideDown 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  `}

  @keyframes slideDown {
    0% {
      transform: translate(-50%, -100%);
    }
    100% {
      transform: translate(-50%, 100%);
    }
  }
`;
