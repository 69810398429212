import { gql } from '@apollo/client';

export const LessonMaterialFragment = gql`
  fragment LessonMaterialFragment on LessonMaterial {
    id
    materialId
    groupType
    name
    numberOfItems
    normalisedNumberOfItems
    numberInGroup
    note
    alternativeMaterials {
      id
      name
    }
    isOptional
    isReusable
    isStandardised
    unitOfMeasurementId
    unitsOfMeasurement {
      id
      name
      abbr
      isDefault
      categoryId
    }
  }
`;
