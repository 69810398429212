import styled, { css } from 'styled-components';

import { MarginProps, marginCss } from './spacing.style';

export const baseFlexCss = css`
  display: flex;
  align-items: center;
`;

export const flexCentreAlignCss = css`
  ${baseFlexCss}
  justify-content: center;
`;

export const flexLeftAlignCss = css`
  ${baseFlexCss}
  justify-content: left;
`;

export const flexSpaceBetweenCss = css`
  ${baseFlexCss}
  justify-content: space-between;
`;

export const flexColumnCss = css`
  ${baseFlexCss}
  justify-content: center;
  flex-direction: column;
`;

export const FlexCentreAlign = styled.div<MarginProps>`
  ${() => marginCss()}
  ${flexCentreAlignCss}
`;

export const FlexLeftAlign = styled.div<MarginProps>`
  ${() => marginCss()}
  ${flexLeftAlignCss}
`;

export const flexRightAlignCss = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FlexRightAlign = styled.div<MarginProps>`
  ${() => marginCss()}
  ${flexRightAlignCss}
`;

export const FlexSpaceBetween = styled.div<MarginProps>`
  ${() => marginCss()}
  ${flexSpaceBetweenCss}
`;

export const FlexColumn = styled.div<MarginProps>`
  ${() => marginCss()}
  ${flexColumnCss}
`;
