import { ReactNode } from 'react';
import {
  InquisitiveCurriculum,
  InquisitiveSubject,
  InquisitiveYears,
} from 'global/graphql/scalars.type';
import { FilterSubject } from 'webclient.constants';

export const VIEW_UNITS = 'units';
export const VIEW_LESSONS = 'lessons';
export type ViewMode = typeof VIEW_UNITS | typeof VIEW_LESSONS;
export const VIEW_FIELD = 'viewField';
export const VIEW_KEY = 'view';

export type Props = {
  hasDrawer: boolean;
  dataEventCategory: string;
  subject?: InquisitiveSubject;
  children?: ReactNode;
  viewMode?: ViewMode;
  isEditMode?: boolean;
  filterLabelHugsDropdown?: boolean;
  onChangeView?: (mode: ViewMode) => void;
  onFilterChange?: () => void;
};

export type FiltersType = {
  years: InquisitiveYears[];
  curriculum: InquisitiveCurriculum[];
  curriculumVersion: string[];
  subjects: FilterSubject[];
};

export const FILTER_DRAWER_ID = 'filter-drawer';
export const FILTER_QUICK_ID = 'quick-filter';
