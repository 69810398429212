import { css } from 'styled-components';

import { hexColourToRGBArray } from 'lib/strings/strings.colour';
import { rem, mediaUp } from 'approot/shared/style';
import { grey9, purple8 } from 'approot/shared/styles/colours.style';

export const borderRadius8 = 8;
export const borderRadius16 = 16;
export const baseBoxShadowFocus = `0 0 0 2px ${grey9}`;

export const standardBorderRadius = css`
  border-radius: ${rem(borderRadius8)};

  ${mediaUp.md`
    border-radius: ${rem(borderRadius16)};
  `}
`;

export const boxShadow815Grey = css`
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.14);
`;

export const standardFocusState = css`
  &:focus {
    box-shadow: ${baseBoxShadowFocus};
    outline: 0;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: ${baseBoxShadowFocus};
  }
`;

export const standardFocusStateInset = css`
  &:focus {
    box-shadow: inset ${baseBoxShadowFocus};
    outline: 0;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: inset ${baseBoxShadowFocus};
  }
`;

export const standardInsetBoxShadow = css`
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.24);
`;

export const activeInsetBoxShadow = css`
  box-shadow: inset 0px 4px 8px
    rgba(${hexColourToRGBArray(purple8).join(',')}, 0.24);
`;

export const boxShadowBlack8 = css`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

export const boxShadowBlack12 = css`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
`;

export const negativeInsetBoxShadow = css`
  box-shadow: inset 0px 4px 8px rgba(207, 19, 34, 0.24);
`;

export const bigBoxShadow = css`
  box-shadow: 0px 16px 32px 0px rgba(22, 34, 51, 0.16),
    0px 4px 12px -4px rgba(22, 34, 51, 0.12);
`;
