import { gql } from '@apollo/client';

export const unitStrandsFragment = gql`
  fragment UnitStrandsFragment on Unit {
    id
    strands {
      id
      name
    }
  }
`;

export const unitSubStrandsFragment = gql`
  fragment UnitSubStrandsFragment on Unit {
    id
    subStrands {
      id
      name
    }
  }
`;

export const GET_STRANDS = gql`
  query GetStrands($input: GetStrandInput!) {
    strands(input: $input) {
      id
      name
    }
  }
`;

export const ADD_STRAND = gql`
  mutation AddStrand($input: AddUnitStrandInput!) {
    addUnitStrand(input: $input) {
      ...UnitStrandsFragment
    }
  }
  ${unitStrandsFragment}
`;

export const DELETE_STRAND = gql`
  mutation DeleteStrand($input: DeleteUnitStrandInput!) {
    deleteUnitStrand(input: $input) {
      ...UnitStrandsFragment
    }
  }
  ${unitStrandsFragment}
`;

export const GET_SUBSTRANDS = gql`
  query GetSubStrands($input: GetSubStrandsInput!) {
    subStrands(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_SUBSTRAND = gql`
  mutation UpdateSubStrand($input: UpdateUnitSubStrandInput!) {
    updateUnitSubStrand(input: $input) {
      ...UnitSubStrandsFragment
    }
  }
  ${unitSubStrandsFragment}
`;
