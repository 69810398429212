import styled from 'styled-components';

import { rem, mediaUp } from 'approot/shared/style';
import {
  purple2,
  purple6,
  purple7,
  grey3,
  grey4,
  grey5,
  grey6,
  grey7,
  grey10,
  grey1,
} from 'approot/shared/styles/colours.style';
import {
  font18,
  fontWeightMedium,
  PStyled,
} from 'approot/shared/styles/typography.style';
import { spacing4, spacing8 } from 'approot/shared/styles/spacing.style';

type RadioInputWrapperProps = {
  inline?: boolean;
  disabled?: boolean;
  marginBottom?: number;
};

const LABEL_HEIGHT = 26;

export const RadioInputLabel = styled.label<RadioInputWrapperProps>`
  display: flex;
  cursor: pointer;
  margin-bottom: ${props =>
    props.marginBottom ? rem(props.marginBottom) : '0'};

  ${({ inline }) =>
    inline &&
    `
    display: inline-flex;
  `};

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
  `};
`;

export const RadioInputFieldWrapper = styled.div`
  height: ${rem(LABEL_HEIGHT)};
  display: flex;
  align-items: center;
  align-self: center;
  flex: 0 0 auto; // needs to be explicitly set for ie11, so that RadioInputDecor's margin doesn't collapse
`;

export const RadioInputField = styled.input`
  opacity: 0;
  position: absolute;
  z-index: 1; // necessary for the hover to work in IE
  cursor: pointer;
`;

type RadioInputDecorProps = {
  labelBefore?: boolean;
  marginRight?: number;
};

export const RadioInputDecor = styled.div<RadioInputDecorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${rem(1)} solid ${grey7};
  background-color: ${grey1};
  box-sizing: border-box;
  height: ${rem(16)};
  width: ${rem(16)};
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: ${props =>
    props.marginRight ? rem(props.marginRight) : rem(spacing8)};
  outline: none;

  ${({ labelBefore }) =>
    labelBefore &&
    `
    margin-left: ${rem(15)};
  `}

  & > div {
    height: ${rem(8)};
    width: ${rem(8)};
    top: ${rem(3)};
    left: ${rem(3)};
    border-radius: 50%;
    background-color: transparent;
  }

  // ACTIVE STATES
  input:checked + & {
    border: ${rem(1)} solid ${purple7};
  }
  input:checked + & > div {
    background-color: ${purple7};
  }

  // FOCUS STATES
  input:focus + & {
    box-shadow: 0 0 0 4px ${grey4};
    background-color: ${grey4};
  }
  input:focus:checked + & {
    box-shadow: 0 0 0 4px ${purple2};
    background-color: ${purple2};
  }

  // HOVER STATES
  input:hover + &,
  input:hover:checked + & {
    border: ${rem(1)} solid ${purple6};
  }
  input:hover:checked + & > div {
    background-color: ${purple6};
  }

  // DISABLED STATES
  input:disabled + &,
  input:disabled:checked + & {
    box-shadow: none;
    border: ${rem(1)} solid ${grey5};
  }
  input:disabled:checked + & {
    background-color: ${grey3};
  }
  input:disabled:checked + & > div {
    background-color: ${grey6};
  }
`;

type RadioInputTextProps = {
  disabled?: boolean;
  isLabelBold?: boolean;
};

export const RadioInputTextWrapper = styled.div<RadioInputTextProps>`
  ${font18};
  color: ${grey10};
  ${({ isLabelBold }) =>
    isLabelBold &&
    `
    font-weight: ${fontWeightMedium};
  `}

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
  `};
`;

export const RadioInputPrimaryTextWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: ${rem(LABEL_HEIGHT)};
  flex-direction: column;
  align-items: flex-start;
  ${mediaUp.sm`
    flex-direction: row;
  `};
`;

type RadioInputPrimaryTextProps = {
  hasInlineText?: boolean;
};

export const RadioInputPrimaryText = styled.span<RadioInputPrimaryTextProps>`
  margin-right: ${rem(spacing8)};

  // on larger screens with inline text to the right, don't let the text
  // drop to a new line (for aesthetic purposes)
  ${mediaUp.sm<RadioInputPrimaryTextProps>`
    white-space: ${props => (props.hasInlineText ? 'nowrap' : 'normal')};
  `};
`;

export const RadioInputSecondaryText = styled(PStyled)`
  margin-top: ${rem(spacing4)};
  margin-bottom: 0;
`;
