import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { InquisitiveYears } from 'global/graphql/scalars.type';
import { NAMESPACE_TRANSLATION } from 'internationalise';
import { ALL_WORD, Years } from 'global/constants';

type FormatParams = {
  lower?: boolean;
  plural?: boolean;
  prefix?: boolean;
};

export function getYearsEducationRange(t: TFunction): string {
  return `${getYearLabel(t, { plural: true })} ${t('content.educationRange')}`;
}

export function getYearLabel(
  t: TFunction,
  formatParams?: FormatParams
): string {
  const label = t('content.year', {
    count: formatParams?.plural ? 2 : 1,
  }).toString();
  return formatParams?.lower ? label.toLowerCase() : label;
}

export function getAbbreviatedYearLabel(
  year: InquisitiveYears,
  translate: TFunction
) {
  if (year === '0') {
    return translate('content.year_0_short');
  }
  if (year === '-1') {
    return translate('content.year_pre_0_short');
  }
  return year;
}

export function formatYears(
  years: InquisitiveYears[],
  translate: TFunction,
  format?: FormatParams
) {
  years = [...years].sort();

  // return full label for years which are not numerical
  if (years.length === 1 && years[0] === '0') {
    return translate('content.year_0');
  }
  if (years.length === 1 && years[0] === '-1') {
    return translate('content.year_pre_0');
  }

  let label = `${
    format?.prefix === false
      ? ''
      : `${getYearLabel(translate, {
          plural: years.length > 1,
          lower: format?.lower,
        })} `
  }`;
  if (years[0] === '0' || years[0] === '-1') {
    label = `${label}${getAbbreviatedYearLabel(years[0], translate)}`;
  } else {
    label = `${label}${years[0]}`;
  }

  const endOfSpan = years.at(-1);
  if (years.length > 1) {
    if (endOfSpan === '0' || endOfSpan === '-1') {
      return `${label} - ${getAbbreviatedYearLabel(endOfSpan, translate)}`;
    } else {
      return `${label} - ${endOfSpan}`;
    }
  }

  return label;
}

export function formatYearsToSingleValues(
  years: InquisitiveYears[],
  translate: TFunction
) {
  const yearsFormatted = years
    .sort()
    .map(year =>
      year === '0'
        ? years.length > 1
          ? translate('content.year_0_short')
          : translate('content.year_0')
        : year === '-1'
        ? years.length > 1
          ? translate('content.year_pre_0_short')
          : translate('content.year_pre_0')
        : year
    );
  const yearPrefix = getYearLabel(translate, { plural: years.length > 1 });
  let yearList = yearsFormatted.join(', ');

  if (years.length === 1 && years[0] === '0') {
    return yearList;
  }
  return `${yearPrefix} ${yearList}`;
}

export const useYearTranslation = () => {
  const { t } = useTranslation(NAMESPACE_TRANSLATION);
  return {
    formatYears: (years: InquisitiveYears[], format?: FormatParams) =>
      formatYears(years, t, format),
    formatYearsToSingleValues: (years: InquisitiveYears[]) =>
      formatYearsToSingleValues(years, t),
    getYearLabel: (format?: FormatParams) => getYearLabel(t, format),
    getYearsEducationRange: () => getYearsEducationRange(t),
  };
};

export function getAllYearsFilterOption(t: TFunction) {
  return {
    value: ALL_WORD as Years,
    label: `All ${getYearLabel(t, { plural: true })}`,
  };
}
