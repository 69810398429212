import { makeVar, gql, useQuery } from '@apollo/client';

import {
  GetContentFilters_contentFilters,
  GetContentFilters,
} from './__generated__/GetContentFilters';
import { ViewMode, VIEW_LESSONS } from './filters.constants';

const contentFiltersVar = makeVar<GetContentFilters_contentFilters>({
  curriculumFilter: null,
  curriculumVersionFilter: null,
  subjectFilter: null,
  yearFilter: null,
  readPreferenceFilter: true,
  triggerContentRefresh: false,
  __typename: 'ContentFilters',
});

export function setReadPreferenceFilter(state: boolean) {
  contentFiltersVar({
    ...contentFiltersVar(),
    ...{
      readPreferenceFilter: state,
    },
  });
}

export function setTriggerContentRefresh(state: boolean) {
  contentFiltersVar({
    ...contentFiltersVar(),
    ...{
      triggerContentRefresh: state,
    },
  });
}

export function setContentFilterData(
  properties: Partial<GetContentFilters_contentFilters>
) {
  const currentValues = contentFiltersVar();
  let data = {
    ...currentValues,
    ...properties,
  };

  if (
    properties.curriculumFilter ||
    properties.curriculumVersionFilter ||
    properties.yearFilter ||
    properties.subjectFilter
  ) {
    data = {
      ...data,
      curriculumFilter:
        properties.curriculumFilter || currentValues.curriculumFilter,
      curriculumVersionFilter:
        properties.curriculumVersionFilter ||
        currentValues.curriculumVersionFilter,
      yearFilter: properties.yearFilter || currentValues.yearFilter,
      subjectFilter: properties.subjectFilter || currentValues.subjectFilter,
    };
  }

  contentFiltersVar(data);
  return data;
}

export function getContentFilters() {
  return contentFiltersVar();
}

export const GET_CONTENT_FILTERS = gql`
  query GetContentFilters {
    contentFilters @client {
      curriculumFilter
      curriculumVersionFilter
      yearFilter
      subjectFilter
      readPreferenceFilter
      triggerContentRefresh
    }
  }
`;

export const localContentFiltersTypeDefs = gql`
  type ContentFilters {
    curriculumFilter: [InquisitiveCurriculum!]
    curriculumVersionFilter: [InquisitiveAUAndVICCurriculumVersions!]
    yearFilter: [InquisitiveYears!]
    subjectFilter: [InquisitiveSubject!]
    readPreferenceFilter: Boolean!
    triggerContentRefresh: Boolean!
  }

  extend type Query {
    contentFilters: ContentFilters
  }
`;

export const useContentFilters = () => {
  const { data } = useQuery<GetContentFilters>(GET_CONTENT_FILTERS);
  return {
    data,
    curriculumFilter: data?.contentFilters?.curriculumFilter,
    curriculumVersionFilter: data?.contentFilters?.curriculumVersionFilter,
    subjectFilter: data?.contentFilters?.subjectFilter,
    yearFilter: data?.contentFilters?.yearFilter,
    readPreferenceFilter: !!data?.contentFilters?.readPreferenceFilter,
    triggerContentRefresh: !!data?.contentFilters?.triggerContentRefresh,
  };
};

const viewModeVar = makeVar<ViewMode>(VIEW_LESSONS);
const isEditingVar = makeVar<boolean>(false);

export function setViewMode(mode: ViewMode) {
  viewModeVar(mode);
}

export function getViewMode() {
  return viewModeVar();
}

export function setIsEditing(isEditing: boolean) {
  isEditingVar(isEditing);
}

export function getIsEditing() {
  return isEditingVar();
}
