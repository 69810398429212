import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { rem } from 'approot/shared/style';
import {
  grey1,
  grey5,
  grey9,
  green6,
  purple7,
  purple1,
  purple8,
  red1,
  red9,
  grey8,
  grey4,
} from 'approot/shared/styles/colours.style';
import {
  spacing4,
  spacing8,
  spacing12,
  spacing16,
} from 'approot/shared/styles/spacing.style';
import {
  CheckboxDecorStyled,
  CheckboxInputStyled,
} from 'lib/form/checkbox-input/checkbox-input.style';
import { RadioInputDecor } from 'lib/form/radio-input/radio-input-new.style';
import {
  borderRadius8,
  standardFocusState,
} from 'approot/shared/styles/borders.style';
import {
  font14,
  font16,
  fontWeightMedium,
} from 'approot/shared/styles/typography.style';
import { baseFlexCss } from '../styles/flex.style';

export type DropdownItemProps = {
  $isNegative?: boolean;
  $isActive?: boolean;
  $isDisabled?: boolean;
  role?: 'option' | 'checkbox' | 'radio' | 'link';
};

export const dropdownItemCommonCss = css<DropdownItemProps>`
  color: ${grey9};
  background: none;
  border: none;
  border-radius: ${rem(borderRadius8)};
  line-height: 1.5;
  padding: ${rem(spacing8)} ${rem(spacing12)};
  position: relative;
  white-space: normal;
  text-decoration: none;
  text-align: left;

  &:hover,
  &:active {
    background-color: ${purple1};
    color: ${purple8};
    cursor: pointer;
    text-decoration: none;
  }

  ${standardFocusState}

  &:focus {
    z-index: 1;
  }

  ${({ $isNegative }) =>
    $isNegative &&
    `
    &:hover {
      background-color: ${red1};
      color: ${red9};
    }
  `}

  ${({ $isActive }) =>
    $isActive &&
    `
    background-color: ${purple1};
    color: ${purple8};
  `}

  &.active {
    background-color: ${purple1};
    color: ${purple8};
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    &:hover {
      background-color: ${grey1};
      color: ${grey8};
      cursor: not-allowed;
    }
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
      opacity: 0.5;
        &:hover {
          background-color: ${grey1};
          color: ${grey8};
          cursor: not-allowed;
        }
    `}
`;

export const DropdownItem = styled.button.attrs<{
  'data-dropdown-item'?: string;
}>({
  type: 'button',
  'data-dropdown-item': 'true',
})<DropdownItemProps>`
  ${dropdownItemCommonCss}
`;

export const DropdownItemFlex = styled(DropdownItem)`
  display: flex;
  align-items: center;
`;

export const DropdownLink = styled(Link)<DropdownItemProps>`
  ${dropdownItemCommonCss}
  display: block;
`;

export const DropdownLinkDisabled = styled.a.attrs({
  'data-dropdown-item': 'true',
  'aria-disabled': true,
  role: 'link',
})<DropdownItemProps>`
  ${dropdownItemCommonCss}
  display: block;
`;

export const DropdownItemLabel = styled.div`
  ${font16}
  color: ${grey9};
`;

export const DropdownItemLabelStrong = styled(DropdownItemLabel)`
  font-weight: ${fontWeightMedium};
`;

export const DropdownItemDescription = styled.div`
  ${font14}
`;

export const DropdownDivider = styled.hr.attrs({
  'aria-hidden': true,
})`
  border-color: ${grey4};
  margin: ${rem(spacing12)} -${rem(spacing12)};
`;

type DropdownItemRadioProps = {
  isActive: boolean;
};

export const DropdownItemRadio = styled(DropdownItem)<DropdownItemRadioProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    fill: none;
  }

  &:hover {
    ${({ isActive }) =>
      isActive
        ? css`
            svg {
              path:last-of-type {
                stroke: ${grey1};
              }
            }
          `
        : css`
            svg {
              path:last-of-type {
                stroke: ${grey5};
              }
            }
          `}
  }

  ${({ isActive }) =>
    isActive
      ? css`
          svg {
            path:first-of-type {
              stroke: ${green6};
            }

            fill: ${green6};
            stroke: ${grey1};
          }
        `
      : css`
          svg {
            stroke: ${grey5};
            path:last-of-type {
              stroke: ${grey1};
            }
          }
        `}
`;

export const CheckboxInput = styled(CheckboxInputStyled)``;

type CheckboxLabelProps = {
  disabled?: boolean;
};
export const CheckboxLabel = styled.label<CheckboxLabelProps>`
  display: flex;
  align-items: center;
  color: ${grey9};
  cursor: pointer;
  max-width: 100%;
  user-select: none;
  position: relative;
  white-space: normal;

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
  `};
  margin: 0;
`;

export const CheckboxDecor = styled(CheckboxDecorStyled)`
  input:indeterminate + & {
    border: 1px solid ${purple7};
    background-color: ${purple7};
    svg {
      display: block;
    }
  }
`;

type DropdownSelectItemProps = {
  checked?: boolean;
};
export const DropdownSelectItem = styled(DropdownItem)<DropdownSelectItemProps>`
  display: flex;
  align-items: center;
  padding: ${rem(spacing8)};

  ${CheckboxDecorStyled}, ${RadioInputDecor} {
    margin-right: ${rem(spacing12)};
    min-width: ${rem(16)};
  }

  ${({ checked }) =>
    checked &&
    `
    ${CheckboxDecorStyled} {
      background-color: ${purple7};
      border-color: ${purple7};
      svg {
        display: block;
        stroke: white;
      }
    }

    ${RadioInputDecor} {
      border-color: ${purple7};
      & > div {
        background-color: ${purple7};
      }
    }
  `}
`;

export const DropdownSelectItemClear = styled.div`
  color: ${purple7};
  padding: ${rem(spacing4)} 0;
`;

export const DropdownAnchor = styled.a.attrs<{ 'data-dropdown-item'?: string }>(
  {
    'data-dropdown-item': 'true',
  }
)<DropdownItemProps>`
  ${dropdownItemCommonCss}
  display: block;
`;

export const DropdownItemSwitch = styled.div`
  ${baseFlexCss}
  ${font16}

  background-color: ${grey1};
  gap: ${rem(spacing12)};
  padding: ${rem(spacing8)} ${rem(spacing12)};
`;

export const DropdownItemIconWrapper = styled.div`
  flex-shrink: 0;
  margin-right: ${rem(spacing16)};
`;
