import {
  PRIVATE_CLASS,
  PRIVATE_CLASS_LOGIN,
  PRIVATE_CLASS_TOKEN,
  PRIVATE_CLASS_LESSON,
  PRIVATE_CLASS_ASSESSMENT,
  PRIVATE_STUDENTS,
  PRIVATE_STUDENTS_IMPORT,
  PRIVATE_CLASSES_PARAM,
  PRIVATE_STUDENT_CLASSES,
  PRIVATE_STUDENT_PROFILE,
  PRIVATE_SIGNIN_REFRESH_STUDENT_SESSION,
} from 'global/private-routes.constants';
import {
  PUBLIC_STUDENTS_BASE,
  PUBLIC_STUDENT_SIGNIN,
} from 'global/public-routes.constants';
import LazyRoute, {
  LazyRouteAuthenticatedStudent,
} from 'approot/shared/routes/lazy-route';
import { STUDENTS_FEATURE_FLAG } from 'approot/students/students.constants';

const StudentClassToDoRoutes = [
  {
    path: PRIVATE_CLASS_ASSESSMENT,
    component: LazyRouteAuthenticatedStudent(() =>
      import('approot/student-assessment/student-assessment')
    ),
  },
  {
    path: PRIVATE_CLASS_LESSON,
    component: LazyRouteAuthenticatedStudent(() =>
      import('approot/student-lesson/student-lesson')
    ),
  },
  {
    path: [PUBLIC_STUDENT_SIGNIN, PRIVATE_CLASS_LOGIN],
    component: LazyRoute(
      () =>
        import(
          'approot/shared/signin/student-passcode-signin/student-passcode-signin-page'
        ),
      false,
      true
    ),
  },
  {
    path: PRIVATE_CLASS_TOKEN,
    component: LazyRoute(
      () => import('approot/student-class-to-do/student-class-to-do-qrhandler'),
      false,
      true
    ),
  },
  {
    path: PRIVATE_CLASS,
    component: LazyRoute(
      () => import('approot/student-class-to-do/student-class-to-do'),
      false,
      true
    ),
  },
  {
    path: `${PRIVATE_CLASSES_PARAM}${PRIVATE_STUDENTS}`,
    component: LazyRoute(
      () => import('approot/students/students'),
      true,
      false,
      STUDENTS_FEATURE_FLAG
    ),
  },
  {
    path: [
      `${PRIVATE_CLASSES_PARAM}${PRIVATE_STUDENTS_IMPORT}`,
      PRIVATE_STUDENTS_IMPORT,
    ],
    component: LazyRoute(
      () => import('approot/students/students-import'),
      true,
      false,
      STUDENTS_FEATURE_FLAG
    ),
  },
  {
    path: PRIVATE_STUDENT_CLASSES,
    component: LazyRoute(
      () => import('approot/students/student-classes'),
      false,
      true
    ),
  },
  {
    path: [PUBLIC_STUDENTS_BASE, PRIVATE_STUDENTS],
    component: LazyRoute(
      () => import('approot/shared/signin/signin-student'),
      false,
      true
    ),
  },
  {
    path: PRIVATE_STUDENT_PROFILE,
    component: LazyRouteAuthenticatedStudent(() =>
      import('approot/student-profile/student-profile')
    ),
  },
  {
    path: PRIVATE_SIGNIN_REFRESH_STUDENT_SESSION,
    component: LazyRouteAuthenticatedStudent(() =>
      import(
        'approot/shared/signin/student-signin/signin-refresh-student-session/signin-refresh-student-session-page'
      )
    ),
  },
];

export default StudentClassToDoRoutes;
