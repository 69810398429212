import { gql } from '@apollo/client';

export const unitSupportingMaterialFragment = gql`
  fragment UnitSupportingMaterialsFragment on Unit {
    id
    data {
      supportingMaterials {
        uuid
        name
        description
        link
        type
        author
      }
    }
  }
`;

export const SAVE_UNIT_SUPPORTING_MATERIAL = gql`
  mutation SaveUnitSupportingMaterial(
    $input: UpdateUnitSupportingMaterialInput!
  ) {
    updateUnitSupportingMaterial(input: $input) {
      ...UnitSupportingMaterialsFragment
    }
  }
  ${unitSupportingMaterialFragment}
`;

export const DELETE_UNIT_SUPPORTING_MATERIAL = gql`
  mutation DeleteUnitSupportingMaterial(
    $input: DeleteUnitSupportingMaterialInput!
  ) {
    deleteUnitSupportingMaterial(input: $input) {
      ...UnitSupportingMaterialsFragment
    }
  }
  ${unitSupportingMaterialFragment}
`;
