import {
  azure1,
  azure2,
  azure3,
  azure6,
  blue1,
  blue2,
  blue3,
  blue6,
  cyan1,
  cyan2,
  cyan3,
  cyan6,
  gold1,
  gold2,
  gold3,
  gold6,
  green1,
  green2,
  green3,
  green6,
  grey10,
  grey2,
  grey3,
  grey4,
  grey5,
  grey6,
  lime1,
  lime2,
  lime3,
  lime6,
  magenta1,
  magenta2,
  magenta3,
  magenta6,
  orange1,
  orange2,
  orange3,
  orange6,
  purple1,
  purple2,
  purple3,
  purple6,
  red1,
  red2,
  red3,
  red6,
  volcano1,
  volcano2,
  volcano3,
  volcano6,
  yellow1,
  yellow2,
  yellow3,
  yellow6,
} from 'approot/shared/styles/colours.style';

export const COLOUR_BLACK = {
  colour1: grey2,
  colour2: grey4,
  colour3: grey5,
  base: grey10,
  name: 'black',
};

export const COLOUR_GREY = {
  colour1: grey2,
  colour2: grey3,
  colour3: grey5,
  base: grey6,
  name: 'grey',
};

export const COLOUR_BLUE = {
  colour1: blue1,
  colour2: blue2,
  colour3: blue3,
  base: blue6,
  name: 'blue',
};

export const COLOUR_PURPLE = {
  colour1: purple1,
  colour2: purple2,
  colour3: purple3,
  base: purple6,
  name: 'purple',
};

export const COLOUR_RED = {
  colour1: red1,
  colour2: red2,
  colour3: red3,
  base: red6,
  name: 'red',
};

export const COLOUR_VOLCANO = {
  colour1: volcano1,
  colour2: volcano2,
  colour3: volcano3,
  base: volcano6,
  name: 'volcano',
};

export const COLOUR_ORANGE = {
  colour1: orange1,
  colour2: orange2,
  colour3: orange3,
  base: orange6,
  name: 'orange',
};

export const COLOUR_GOLD = {
  colour1: gold1,
  colour2: gold2,
  colour3: gold3,
  base: gold6,
  name: 'gold',
};

export const COLOUR_YELLOW = {
  colour1: yellow1,
  colour2: yellow2,
  colour3: yellow3,
  base: yellow6,
  name: 'yellow',
};

export const COLOUR_LIME = {
  colour1: lime1,
  colour2: lime2,
  colour3: lime3,
  base: lime6,
  name: 'lime',
};

export const COLOUR_GREEN = {
  colour1: green1,
  colour2: green2,
  colour3: green3,
  base: green6,
  name: 'green',
};

export const COLOUR_CYAN = {
  colour1: cyan1,
  colour2: cyan2,
  colour3: cyan3,
  base: cyan6,
  name: 'cyan',
};

export const COLOUR_AZURE = {
  colour1: azure1,
  colour2: azure2,
  colour3: azure3,
  base: azure6,
  name: 'azure',
};

export const COLOUR_MAGENTA = {
  colour1: magenta1,
  colour2: magenta2,
  colour3: magenta3,
  base: magenta6,
  name: 'magenta',
};
