import styled, { css } from 'styled-components';

export const PrintCss = css`
  @page {
    size: A4 portrait;
    margin: 1cm;
  }

  @media print {
    * {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important;
    }

    body {
      padding: 0;
      margin: 0 auto;
      overflow-x: hidden;
    }
  }
`;

export const PrintBlockStyled = styled.div`
  display: none !important;

  @media print {
    display: block !important;
  }
`;

export const PrintHidden = css`
  @media print {
    display: none !important;
  }
`;
