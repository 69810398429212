import { nanoid } from 'nanoid';

import { getUserId } from 'approot/shared/api/auth/auth';
import { getStudentUserId } from 'approot/shared/api/auth/student-user-auth';

export function generateTraceId() {
  const userId = getUserId();
  const studentUserId = getStudentUserId();
  const uuid = nanoid();

  if (studentUserId) {
    return `${uuid}-${studentUserId}`;
  }
  if (userId) {
    return `${uuid}-${userId}`;
  }
  return uuid;
}
