import { useQuery } from '@apollo/client';

import { closeGlobalModal, GET_CURRENT_MODAL } from './global-modals.apollo';
import { GetGlobalModal } from './__generated__/GetGlobalModal';
import { ModalKey } from 'approot/shared/modal/modal.constants';

export const useGlobalModal = () => {
  const { data } = useQuery<GetGlobalModal>(GET_CURRENT_MODAL);
  return {
    // globalModal is always read from globalModalVar (typed as ModalKey) through the cache (typed as a string)
    globalModal: (data?.globalModal as ModalKey) || ModalKey.NONE,
    closeGlobalModal: closeGlobalModal,
  };
};
