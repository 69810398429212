import { AnyObject } from 'webclient.constants';
import {
  E_CLICKS_LOGGED_IN,
  E_CLICKS_LOGGED_OUT,
} from 'approot/shared/data-tracker/e-clicks-data-event';

declare var window: GaWindow;

type GaWindow = {
  [key: string]: any;
};

export const updateAnalyticsDataLayer = (userFields: AnyObject) => {
  if (!window['dataLayer']) {
    // Google Analytics adds this so if it's not present
    // then a browser plugin has blocked it, or Cypress has blocked it
    return;
  }
  if (userFields.id) {
    window['dataLayer'].push({
      userId: userFields.id,
      status: E_CLICKS_LOGGED_IN,
      position: userFields.position,
    });
  }
  if (userFields.classId) {
    window['dataLayer'].push({ classId: userFields.classId });
  }
};

export const pushPageviewToDataLayer = () => {
  // We only need to call this function to trigger an extra pageview where desired.
  // GTM automatically triggers pageview in most cases in our site
  if (!window['dataLayer']) {
    // Google Analytics adds this so if it's not present
    // then a browser plugin has blocked it, or Cypress has blocked it
    return;
  }
  // In GTM a pageview tag is triggered by a historyChange event with historyChangeSource of 'pushState'
  // This is the same normal pageview tag that triggers from historyChange events when users navigate around our site
  window['dataLayer'].push({
    event: 'gtm.historyChange',
    'gtm.historyChangeSource': 'pushState',
  });
};

export const clearAnalyticsDataLayer = () => {
  if (!window['dataLayer']) {
    // Google Analytics adds this so if it's not present
    // then a browser plugin has blocked it, or Cypress has blocked it
    return;
  }
  window['dataLayer'].push({
    userId: undefined,
    classId: undefined,
    position: undefined,
    status: E_CLICKS_LOGGED_OUT,
  });
};
