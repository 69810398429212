import { useEffect } from 'react';
import {
  useLocation,
  NavigateFunction,
  useNavigate,
  Location,
} from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/client';

import { IsSignedIn } from 'approot/shared/user/__generated__/IsSignedIn';
import {
  IS_SIGNED_IN,
  SIGN_IN_REDIRECTION_PATH,
} from 'approot/shared/user/user-data.graphql';
import {
  PUBLIC_SIGNIN,
  PUBLIC_STUDENT_SIGNIN,
} from 'global/public-routes.constants';

export function goToSignIn(
  client: any,
  redirectPath: string,
  navigate: NavigateFunction,
  location: Location
) {
  if (location.pathname.startsWith(PUBLIC_SIGNIN)) {
    // we already are on the signin page
    return true;
  }

  client.writeQuery({
    query: SIGN_IN_REDIRECTION_PATH,
    data: {
      signInRedirectionPath: redirectPath
        ? redirectPath
        : `${location.pathname}${location.search}`,
    },
  });
  navigate(PUBLIC_SIGNIN, { replace: true });
}

// This hook will listen to changes in the sign in state.
const useCheckAuthBeforeMount = (
  isAuthenticatedRoute: boolean = false,
  isStudentRoute: boolean,
  redirectPath: string = ''
) => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = useQuery<IsSignedIn>(IS_SIGNED_IN);
  const isSignedIn = !!(data && data.isSignedIn);
  const isStudentSignedIn = !!(data && data.isStudentSignedIn);

  useEffect(() => {
    if (isAuthenticatedRoute && isStudentRoute && !isStudentSignedIn) {
      // redirect to student signin screen
      navigate(PUBLIC_STUDENT_SIGNIN, { replace: true });
    } else if (isAuthenticatedRoute && !isStudentRoute && !isSignedIn) {
      // redirect to standard signin screen
      goToSignIn(client, redirectPath, navigate, location);
    }
    //eslint-disable-next-line
  }, [isSignedIn, isStudentSignedIn]);

  return {
    isSignedIn,
    isStudentSignedIn,
  };
};

export default useCheckAuthBeforeMount;
