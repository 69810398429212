import * as Styled from './quisi.style';

import quisiStatic from 'approot/shared/images/quisi-static.png';

const QuisiStatic = ({ marginTop }: { marginTop?: number }) => {
  return (
    <Styled.QuisiStatic marginTop={marginTop} id="quisiContainer">
      <img src={quisiStatic} alt="" />
    </Styled.QuisiStatic>
  );
};

export default QuisiStatic;
