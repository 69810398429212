import orderBy from 'lodash/orderBy';

import { UserFragment_userClasses } from 'approot/shared/user/__generated__/UserFragment';
import { ClassTeacherFragment } from './__generated__/ClassTeacherFragment';
import { CLASS_COLOURS } from 'approot/classes/classes.constants';
import { COLOUR_ORANGE } from 'approot/shared/colour/colour.constants';

export function sortClasses(classes: UserFragment_userClasses[] | undefined) {
  if (!classes) return [];

  return orderBy(classes, ['createdAt'], ['desc']);
}

export function getActiveClasses(
  classes: UserFragment_userClasses[] | undefined
) {
  return sortClasses(classes?.filter(c => !c.archivedAt));
}

export function getArchivedClasses(
  classes: UserFragment_userClasses[] | undefined
) {
  return sortClasses(classes?.filter(c => c.archivedAt));
}

export function getClassTeacher(
  userClass: UserFragment_userClasses | undefined,
  userId: number
) {
  return userClass?.classTeachers.find(t => t.userId === userId);
}

export function getTeacherName(classTeacher: ClassTeacherFragment) {
  return classTeacher.firstName
    ? `${classTeacher.firstName} ${classTeacher.lastName}`
    : classTeacher.email;
}

function getOwnerClasses(classes: UserFragment_userClasses[], userId: number) {
  return classes.filter(c => !c.archivedAt && c.mainTeacher?.userId === userId);
}

export function canArchiveOrLeave({
  classes,
  userId,
  classIdToArchiveOrLeave,
}: {
  classes: UserFragment_userClasses[];
  userId: number;
  classIdToArchiveOrLeave: number;
}) {
  const ownerClasses = getOwnerClasses(classes, userId);

  if (!ownerClasses.length) return false;

  if (ownerClasses.length > 1) {
    return true;
  } else {
    return classIdToArchiveOrLeave !== ownerClasses[0].id;
  }
}

export function getClassColour(classColour: string) {
  return CLASS_COLOURS.find(c => c.base === classColour) || COLOUR_ORANGE;
}
